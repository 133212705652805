import * as React from 'react';
import { graphql } from 'gatsby';
import { useTranslation} from 'gatsby-plugin-react-i18next';

// Components
import Layout from '../../components/layout/Layout';
import EmailSignatureGeneratorForm from '../../components/form/EmailSignatureGenerator';

const EmailSignatureGenerator = ({data}) => {
	
  const {t} = useTranslation();

  return (
    <Layout 
		pageType="classic"
		pageTitle={t('meta.title')}
		pageDescription={t('meta.description')} 
		pageKeywords={t('meta.keywords')} 
		headerClass="navbar-absolute-top navbar-light navbar-show-hide" 
		headerStyle="light"
	>
		<div className="position-relative">
			<div className="content-space-t-3 content-space-t-lg-3 content-space-b-4 position-relative zi-2">
				<div className="w-100 text-center mx-md-auto mb-5 mb-md-9">
					<h1 dangerouslySetInnerHTML={{__html: t('title') }}></h1>
					<p>{t('subtitle')}</p>
				</div>
				<div className="row">
					<div className="container">
						<div className="px-4 w-100 mx-md-auto">
							<EmailSignatureGeneratorForm data={data} />
						</div>
					</div>
				</div>
			</div>
			<div className="shape shape-bottom zi-1">
				<svg width="3000" height="500" viewBox="0 0 3000 500" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0 500H3000V0L0 500Z"/>
				</svg>
			</div>
		</div>		
    </Layout>
  )
}

export default EmailSignatureGenerator

export const query = graphql`
  query ($language: String!) {
	site: site {
		siteMetadata {
			title
		}
	}
    locales: allLocale(filter: {ns: {in: ["common", "header", "resources-email-signature-generator", "share-on", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

