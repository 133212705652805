import React, { useState, useEffect } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { useLocation } from '@reach/router';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import Select from 'react-select';
import IntlTelInput from 'react-intl-tel-input';
import { HexColorInput } from 'react-colorful';
import { toast } from 'react-toastify';

// Components
import ShareBlock from '../../components/block/Share';

// Utilities
import { PopoverPicker } from '../../utilities/PopoverPicker';

// Images
import defaultCTAImage from '../../images/resources/email-signature-generator/cta-image.png';
import defaultProfilePicture from '../../images/resources/email-signature-generator/profile-picture.png';
import defaultCompanyLogo from '../../images/resources/email-signature-generator/company-logo.png';

const EmailSignatureGeneratorForm = ({ data }) => {

  const { t } = useTranslation();

  const location = useLocation();
  const origin = location.origin;

  // Signature
  const [signatureHTML, setSignatureHTML] = useState("");
  
  // Template
  const [template, setTemplate] = useState(localStorage.getItem("template") ? localStorage.getItem("template") : "1");

  // Handle Click Template
  const handleClickTemplate = ({target}) => {
    setTemplate(target.value);
    setValue("template",target.value);
    localStorage.setItem("template",target.value);
  }

  // Theme Color
  const [themeColor, setThemeColor] = useState(localStorage.getItem("theme_color") ? localStorage.getItem("theme_color") : "#377dff");

  // Handle Change Theme Color
  const handleChangeThemeColor = (value) => {
    setThemeColor(value);
    setValue("theme_color",value);
    localStorage.setItem("theme_color",value);
  };

  // Text Color
  const [textColor, setTextColor] = useState(localStorage.getItem("text_color") ? localStorage.getItem("text_color") : "#000000");

  // Handle Change Text Color
  const handleChangeTextColor = (value) => {
    setTextColor(value);
    setValue("text_color",value);
    localStorage.setItem("text_color",value);
  };

  // Font Family
  const [fontFamily, setFontFamily] = useState(localStorage.getItem("font_family") ? JSON.parse(localStorage.getItem("font_family")) : { value: "Arial", label: "Arial" });
  const [fontFamilyValue, setFontFamilyValue] = useState(localStorage.getItem("font_family_value") ? localStorage.getItem("font_family_value") : "Arial");

  // Handle Change Font Family
  const handleChangeFontFamily = (obj) => {
    setFontFamily(obj);
    setValue("font_family",obj);
    localStorage.setItem("font_family",JSON.stringify(obj));
    setFontFamilyValue(obj.value);
    setValue("font_family_value",obj.value);
    localStorage.setItem("font_family_value",obj.value);
  };

  // Options Font Family
  const optionsFontFamily = [
    { value: 'Arial', label: 'Arial' },
    { value: 'Courier New', label: 'Courier New' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Lucida Sans Unicode', label: 'Lucida Sans Unicode' },
    { value: 'Tahoma', label: 'Tahoma' },
    { value: 'Times New Roman', label: 'Times New Roman' },
    { value: 'Trebuchet MS', label: 'Trebuchet MS' },
    { value: 'Verdana', label: 'Verdana' }
  ]

  // Font Size
  const [fontSize, setFontSize] = useState(localStorage.getItem("font_size") ? JSON.parse(localStorage.getItem("font_size")) : { value: "medium", label: t('form.fontSize.medium') });
  const [fontSizeValue, setFontSizeValue] = useState(localStorage.getItem("font_size_value") ? localStorage.getItem("font_size_value") : "medium");
  const [fontSizeTitle, setFontSizeTitle] = useState(localStorage.getItem("font_size_title") ? localStorage.getItem("font_size_title") : "18px");
  const [fontSizeText, setFontSizeText] = useState(localStorage.getItem("font_size_text") ? localStorage.getItem("font_size_text") : "14px");

  // Handle Change Font Size
  const handleChangeFontSize = (obj) => {
    setFontSize(obj);
    setValue("font_size",obj);
    localStorage.setItem("font_size",JSON.stringify(obj));
    setFontSizeValue(obj.value);
    setValue("font_size_value",obj.value);
    localStorage.setItem("font_size_value",obj.value);
    switch (obj.value) {
      case 'small':
        setFontSizeTitle("16px");
        setValue("font_size_title","16px");
        localStorage.setItem("font_size_title","16px");
        setFontSizeText("12px");
        setValue("font_size_text","12px");
        localStorage.setItem("font_size_text","12px");
        break;
      case 'medium':
        setFontSizeTitle("18px");
        setValue("font_size_title","18px");
        localStorage.setItem("font_size_title","18px");
        setFontSizeText("14px");
        setValue("font_size_text","14px");
        localStorage.setItem("font_size_text","14px");
        break;
      case 'large':
        setFontSizeTitle("20px");
        setValue("font_size_title","20px");
        localStorage.setItem("font_size_title","20px");
        setFontSizeText("16px");
        setValue("font_size_text","16px");
        localStorage.setItem("font_size_text","16px");
        break;
      default:
        setFontSizeTitle("18px");
        setValue("font_size_title","18px");
        localStorage.setItem("font_size_title","18px");
        setFontSizeText("14px");
        setValue("font_size_text","14px");
        localStorage.setItem("font_size_text","14px");
        break;
    }
  };

  // Options Font Size
  const optionsFontSize = [
    { value: 'small', label: t('form.fontSize.small') },
    { value: 'medium', label: t('form.fontSize.medium') },
    { value: 'large', label: t('form.fontSize.large') }
  ]

  // Created With
  const [createdWith, setCreatedWith] = useState(localStorage.getItem("created_with") ? localStorage.getItem("created_with") : "checked");

  // Handle Click Created With
  const handleClickCreatedWith = ({target}) => {
    if(createdWith === 'checked'){
      setCreatedWith("");
      setValue("created_with","");
      localStorage.setItem("created_with","");
    }else{
      setCreatedWith("checked");
      setValue("created_with","checked");
      localStorage.setItem("created_with","checked");
    }
  }

  // First Name
  let [firstName, setFirstName] = useState(localStorage.getItem("first_name"));

  // Handle Change First Name
  const handleChangeFirstName = ({target}) => {
    setFirstName(target.value);
    setValue("first_name",target.value);
    localStorage.setItem("first_name",target.value);
  };

  // Last Name
  let [lastName, setLastName] = useState(localStorage.getItem("last_name"));

  // Handle Change Last Name
  const handleChangeLastName = ({target}) => {
    setLastName(target.value);
    setValue("last_name",target.value);
    localStorage.setItem("last_name",target.value);
  };

  // Job Title
  let [jobTitle, setJobTitle] = useState(localStorage.getItem("job_title"));

  // Handle Change Job Title
  const handleChangeJobTitle = ({target}) => {
    setJobTitle(target.value);
    setValue("job_title",target.value);
    localStorage.setItem("job_title",target.value);
  };

  // Department
  let [department, setDepartment] = useState(localStorage.getItem("department"));

  // Handle Change Department
  const handleChangeDepartment = ({target}) => {
    setDepartment(target.value);
    setValue("department",target.value);
    localStorage.setItem("department",target.value);
  };

  // Enterprise
  let [enterprise, setEnterprise] = useState(localStorage.getItem("enterprise"));

  // Handle Change Enterprise
  const handleChangeEnterprise = ({target}) => {
    setEnterprise(target.value);
    setValue("enterprise",target.value);
    localStorage.setItem("enterprise",target.value); 
  };

  // Custom Field
  let [customField, setCustomField] = useState(localStorage.getItem("custom_field"));

  // Handle Change Custom Field
  const handleChangeCustomField = ({target}) => {
    setCustomField(target.value);
    setValue("custom_field",target.value);
    localStorage.setItem("custom_field",target.value);
  };

  // Office Telephone Number
  let [officeTelephoneNumber, setOfficeTelephoneNumber] = useState(localStorage.getItem("office_telephone_number") ? localStorage.getItem("office_telephone_number") : "");
  
  // Handle Change Office Telephone Number
  const handleChangeOfficeTelephoneNumber = (status, phoneNumber, country) => {
    setOfficeTelephoneNumber(phoneNumber);
    setValue("office_telephone_number",phoneNumber);
    localStorage.setItem("office_telephone_number",phoneNumber);
  };

  // Handle Blur Office Telephone Number
  const handleBlurOfficeTelephoneNumber = (status, phoneNumber, country) => {
    setOfficeTelephoneNumber(phoneNumber);
    setValue("office_telephone_number",phoneNumber);
    localStorage.setItem("office_telephone_number",phoneNumber);
  };

  // Mobile Telephone Number
  let [mobileTelephoneNumber, setMobileTelephoneNumber] = useState(localStorage.getItem("mobile_telephone_number") ? localStorage.getItem("mobile_telephone_number") : "");
  
  // Handle Change Mobile Telephone Number
  const handleChangeMobileTelephoneNumber = (status, phoneNumber, country) => {
    setMobileTelephoneNumber(phoneNumber);
    setValue("mobile_telephone_number",phoneNumber);
    localStorage.setItem("mobile_telephone_number",phoneNumber);
  };

  // Handle Blur Mobile Telephone Number
  const handleBlurMobileTelephoneNumber = (status, phoneNumber, country) => {
    setMobileTelephoneNumber(phoneNumber);
    setValue("mobile_telephone_number",phoneNumber);
    localStorage.setItem("mobile_telephone_number",phoneNumber);
  };

  // Email
  let [email, setEmail] = useState(localStorage.getItem("email"));

  // Handle Change Email
  const handleChangeEmail = ({target}) => {
    setEmail(target.value);
    setValue("email",target.value);
    localStorage.setItem("email",target.value);
  };

  // Website URL
  let [websiteURL, setWebsiteURL] = useState(localStorage.getItem("website_url"));

  // Handle Change Website URL
  const handleChangeWebsiteURL = ({target}) => {
    setWebsiteURL(target.value);
    setValue("website_url",target.value);
    localStorage.setItem("website_url",target.value);
  };

  // Address Street Number
  let [addressStreetNumber, setAddressStreetNumber] = useState(localStorage.getItem("address_street_number"));

  // Handle Change Address Street Number
  const handleChangeAddressStreetNumber = ({target}) => {
    setAddressStreetNumber(target.value);
    setValue("address_street_number",target.value);
    localStorage.setItem("address_street_number",target.value);
  };

  // Address Street
  let [addressStreet, setAddressStreet] = useState(localStorage.getItem("address_street"));

  // Handle Change Address Street
  const handleChangeAddressStreet = ({target}) => {
    setAddressStreet(target.value);
    setValue("address_street",target.value);
    localStorage.setItem("address_street",target.value);
  };

  // Address Postal Code
  let [addressPostalCode, setAddressPostalCode] = useState(localStorage.getItem("address_postal_code"));

  // Handle Change Address Postal Code
  const handleChangeAddressPostalCode = ({target}) => {
    setAddressPostalCode(target.value);
    setValue("address_postal_code",target.value);
    localStorage.setItem("address_postal_code",target.value);
  };

  // Address City
  let [addressCity, setAddressCity] = useState(localStorage.getItem("address_city"));

  // Handle Change Address City
  const handleChangeAddressCity = ({target}) => {
    setAddressCity(target.value);
    setValue("address_city",target.value);
    localStorage.setItem("address_city",target.value);
  };

  // Address Country
  let [addressCountry, setAddressCountry] = useState(localStorage.getItem("address_country"));

  // Handle Change Address Country
  const handleChangeAddressCountry = ({target}) => {
    setAddressCountry(target.value);
    setValue("address_country",target.value);
    localStorage.setItem("address_country",target.value);
  };

  // Address URL
  let [addressDirectionURL, setAddressDirectionURL] = useState(localStorage.getItem("address_direction_url"));

  // Handle Change Address URL
  const handleChangeAddressDirectionURL = ({target}) => {
    setAddressDirectionURL(target.value);
    setValue("address_direction_url",target.value);
    localStorage.setItem("address_direction_url",target.value);
  };

  // Contact Icons Size
  const [contactIconsSize, setContactIconsSize] = useState(localStorage.getItem("contact_icons_size") ? JSON.parse(localStorage.getItem("contact_icons_size")) : { value: "14", label: t('form.contactIconsSize.medium') });
  const [contactIconsSizeValue, setContactIconsSizeValue] = useState(localStorage.getItem("contact_icons_size_value") ? localStorage.getItem("contact_icons_size_value") : "14");

  // Handle Change Contact Icons Size
  const handleChangeContactIconsSize = (obj) => {
    setContactIconsSize(obj);
    setValue("contact_icons_size",obj);
    localStorage.setItem("contact_icons_size",JSON.stringify(obj));
    setContactIconsSizeValue(obj.value);
    setValue("contact_icons_size_value",obj.value);
    localStorage.setItem("contact_icons_size_value",obj.value);
  };

  // Options Contact Icons Size
  const optionsContactIconsSize = [
    { value: '10', label: t('form.contactIconsSize.small') },
    { value: '14', label: t('form.contactIconsSize.medium') },
    { value: '18', label: t('form.contactIconsSize.large') }
  ]

  // Contact Icons Color
  const [contactIconsCustomColor, setContactIconsCustomColor] = useState(localStorage.getItem("contact_icons_custom_color") ? localStorage.getItem("contact_icons_custom_color") : "#377dff");

  // Handle Change Contact Icons Color
  const handleChangeContactIconsCustomColor = (value) => {
    setContactIconsCustomColor(value);
    setValue("contact_icons_custom_color",value);
    localStorage.setItem("contact_icons_custom_color",value);
  };

  // Contact Icons Style
  const [contactIconsStyle, setContactIconsStyle] = useState(localStorage.getItem("contact_icons_style") ? JSON.parse(localStorage.getItem("contact_icons_style")) : { value: "classic", label: t('form.contactIconsStyle.classic') });
  const [contactIconsStyleValue, setContactIconsStyleValue] = useState(localStorage.getItem("contact_icons_style_value") ? localStorage.getItem("contact_icons_style_value") : "classic");

  // Handle Change Contact Icons Style
  const handleChangeContactIconsStyle = (obj) => {
    setContactIconsStyle(obj);
    setValue("contact_icons_style",obj);
    localStorage.setItem("contact_icons_style",JSON.stringify(obj));
    setContactIconsStyleValue(obj.value);
    setValue("contact_icons_style_value",obj.value);
    localStorage.setItem("contact_icons_style_value",obj.value);
  };

  // Options Contact Icons Style
  const optionsContactIconsStyle = [
    { value: 'classic', label: t('form.contactIconsStyle.classic') },
    { value: 'border', label: t('form.contactIconsStyle.border') },
    { value: 'background', label: t('form.contactIconsStyle.background') }
  ]

  // Contact Icons Shape
  const [contactIconsShape, setContactIconsShape] = useState(localStorage.getItem("contact_icons_shape") ? JSON.parse(localStorage.getItem("contact_icons_shape")) : { value: "square", label: t('form.contactIconsShape.square') });
  const [contactIconsShapeValue, setContactIconsShapeValue] = useState(localStorage.getItem("contact_icons_shape_value") ? localStorage.getItem("contact_icons_shape_value") : "square");

  // Handle Change Contact Icons Shape
  const handleChangeContactIconsShape = (obj) => {
    setContactIconsShape(obj);
    setValue("contact_icons_shape",obj);
    localStorage.setItem("contact_icons_shape",JSON.stringify(obj));
    setContactIconsShapeValue(obj.value);
    setValue("contact_icons_shape_value",obj.value);
    localStorage.setItem("contact_icons_shape_value",obj.value);
  };

  // Options Contact Icons Shape
  const optionsContactIconsShape = [
    { value: 'square', label: t('form.contactIconsShape.square') },
    { value: 'round', label: t('form.contactIconsShape.round') },
    { value: 'circle', label: t('form.contactIconsShape.circle') }
  ]

  // Social Networks
  let [socialNetworks, setSocialNetworks] = useState(localStorage.getItem("social_networks") ? JSON.parse(localStorage.getItem("social_networks")) : "");

  const handleAddSocialNetwork = () => {
    const values = [...localStorage.getItem("social_networks") ? JSON.parse(localStorage.getItem("social_networks")) : ""];
    values.push({
      type: "",
      text: "",
    });
    setSocialNetworks(values);
    setValue("social_networks",values);
    localStorage.setItem("social_networks",JSON.stringify(values));
  };

  const handleRemoveSocialNetwork = (index) => {
    const values = [...localStorage.getItem("social_networks") ? JSON.parse(localStorage.getItem("social_networks")) : ""];
    values.splice(index, 1);
    setSocialNetworks(values);
    setValue("social_networks",values);
    localStorage.setItem("social_networks",JSON.stringify(values));
  };

  // Handle Change Social Network Type
  const handleChangeSocialNetworkType = (index, event) => {
    const values = [...localStorage.getItem("social_networks") ? JSON.parse(localStorage.getItem("social_networks")) : ""];
    values[index]["type"] = event;
    setSocialNetworks(values);
    setValue("social_networks",values);
    localStorage.setItem("social_networks",JSON.stringify(values));
  };

  const handleChangeSocialNetworkText = (index, event) => {
    const values = [...localStorage.getItem("social_networks") ? JSON.parse(localStorage.getItem("social_networks")) : ""];
    values[index]["text"] = event.target.value;
    setSocialNetworks(values);
    setValue("social_networks",values);
    localStorage.setItem("social_networks",JSON.stringify(values));
  };

  // Options Social Network Type
  const optionsSocialNetworkType = [
    { value: 'dribbble', label: t('form.socialNetworkType.dribbble') },
    { value: 'facebook', label: t('form.socialNetworkType.facebook') },
    { value: 'flickr', label: t('form.socialNetworkType.flickr') },
    { value: 'foursquare', label: t('form.socialNetworkType.foursquare') },
    { value: 'github', label: t('form.socialNetworkType.github') },
    { value: 'instagram', label: t('form.socialNetworkType.instagram') },
    { value: 'line', label: t('form.socialNetworkType.line') },
    { value: 'linkedInCompany', label: t('form.socialNetworkType.linkedInCompany') },
    { value: 'linkedInProfile', label: t('form.socialNetworkType.linkedInProfile') },
    { value: 'medium', label: t('form.socialNetworkType.medium') },
    { value: 'messenger', label: t('form.socialNetworkType.messenger') },
    { value: 'pinterest', label: t('form.socialNetworkType.pinterest') },
    { value: 'snapchat', label: t('form.socialNetworkType.snapchat') },
    { value: 'stackOverflow', label: t('form.socialNetworkType.stackOverflow') },
    { value: 'telegram', label: t('form.socialNetworkType.telegram') },
    { value: 'tiktok', label: t('form.socialNetworkType.tiktok') },
    { value: 'tripadvisor', label: t('form.socialNetworkType.tripadvisor') },
    { value: 'twitch', label: t('form.socialNetworkType.twitch') },
    { value: 'twitter', label: t('form.socialNetworkType.twitter') },
    { value: 'viber', label: t('form.socialNetworkType.viber') },
    { value: 'vimeo', label: t('form.socialNetworkType.vimeo') },
    { value: 'vkontakte', label: t('form.socialNetworkType.vkontakte') },
    { value: 'wechat', label: t('form.socialNetworkType.wechat') },
    { value: 'whatsapp', label: t('form.socialNetworkType.whatsapp') },
    { value: 'yelp', label: t('form.socialNetworkType.yelp') },
    { value: 'youtube', label: t('form.socialNetworkType.youtube') }
  ]

  // Social Network Type
  const socialNetworkTypes = [
    { value: 'dribbble', label: t('form.socialNetworkType.dribbble'), url: { value: 'https://dribbble.com/', position: 'prepend' } },
    { value: 'facebook', label: t('form.socialNetworkType.facebook'), url: { value: 'https://www.facebook.com/', position: 'prepend' } },
    { value: 'flickr', label: t('form.socialNetworkType.flickr'), url: { value: 'https://www.flickr.com/people/', position: 'prepend' } },
    { value: 'foursquare', label: t('form.socialNetworkType.foursquare'), url: { value: 'https://www.foursquare.com/v/', position: 'prepend' } },
    { value: 'github', label: t('form.socialNetworkType.github'), url: { value: 'https://github.com/', position: 'prepend' } },
    { value: 'instagram', label: t('form.socialNetworkType.instagram'), url: { value: 'https://www.instagram.com/', position: 'prepend' } },
    { value: 'line', label: t('form.socialNetworkType.line'), url: { value: 'https://line.me/R/ti/p/', position: 'prepend' } },
    { value: 'linkedInCompany', label: t('form.socialNetworkType.linkedInCompany'), url: { value: 'https://www.linkedin.com/company/', position: 'prepend' } },
    { value: 'linkedInProfile', label: t('form.socialNetworkType.linkedInProfile'), url: { value: 'https://www.linkedin.com/in/', position: 'prepend' } },
    { value: 'medium', label: t('form.socialNetworkType.medium'), url: { value: 'https://medium.com/@', position: 'prepend' } },
    { value: 'messenger', label: t('form.socialNetworkType.messenger'), url: { value: 'http://m.me/', position: 'prepend' } },
    { value: 'pinterest', label: t('form.socialNetworkType.pinterest'), url: { value: 'https://www.pinterest.com/', position: 'prepend' } },
    { value: 'snapchat', label: t('form.socialNetworkType.snapchat'), url: { value: 'https://www.snapchat.com/add/', position: 'prepend' } },
    { value: 'stackOverflow', label: t('form.socialNetworkType.stackOverflow'), url: { value: 'https://stackoverflow.com/users/', position: 'prepend' } },
    { value: 'telegram', label: t('form.socialNetworkType.telegram'), url: { value: 'https://www.t.me/', position: 'prepend' } },
    { value: 'tiktok', label: t('form.socialNetworkType.tiktok'), url: { value: 'https://www.tiktok.com/@', position: 'prepend' } },
    { value: 'tripadvisor', label: t('form.socialNetworkType.tripadvisor'), url: { value: 'https://www.tripadvisor.com/', position: 'prepend' } },
    { value: 'twitch', label: t('form.socialNetworkType.twitch'), url: { value: 'https://www.twitch.tv/', position: 'prepend' } },
    { value: 'twitter', label: t('form.socialNetworkType.twitter'), url: { value: 'https://twitter.com/', position: 'prepend' } },
    { value: 'viber', label: t('form.socialNetworkType.viber'), url: { value: 'viber://chat?number=', position: 'prepend' } },
    { value: 'vimeo', label: t('form.socialNetworkType.vimeo'), url: { value: 'https://www.vimeo.com/', position: 'prepend' } },
    { value: 'vkontakte', label: t('form.socialNetworkType.vkontakte'), url: { value: 'https://vk.com/', position: 'prepend' } },
    { value: 'wechat', label: t('form.socialNetworkType.wechat'), url: { value: 'https://u.wechat.com/', position: 'prepend' } },
    { value: 'whatsapp', label: t('form.socialNetworkType.whatsapp'), url: { value: 'https://wa.me/', position: 'prepend' } },
    { value: 'yelp', label: t('form.socialNetworkType.yelp'), url: { value: 'https://www.linkedin.com/company/', position: 'prepend' } },
    { value: 'youtube', label: t('form.socialNetworkType.youtube'), url: { value: 'https://www.youtube.com/c/', position: 'prepend' } }
  ]

  // Social Network Icons Size
  const [socialNetworkIconsSize, setSocialNetworkIconsSize] = useState(localStorage.getItem("social_network_icons_size") ? JSON.parse(localStorage.getItem("social_network_icons_size")) : { value: "24", label: t('form.socialNetworkIconsSize.medium') });
  const [socialNetworkIconsSizeValue, setSocialNetworkIconsSizeValue] = useState(localStorage.getItem("social_network_icons_size_value") ? localStorage.getItem("social_network_icons_size_value") : "24");

  // Handle Change Social Network Icons Size
  const handleChangeSocialNetworkIconsSize = (obj) => {
    setSocialNetworkIconsSize(obj);
    setValue("social_network_icons_size",obj);
    localStorage.setItem("social_network_icons_size",JSON.stringify(obj));
    setSocialNetworkIconsSizeValue(obj.value);
    setValue("social_network_icons_size_value",obj.value);
    localStorage.setItem("social_network_icons_size_value",obj.value);
  };

  // Options Social Network Icons Size
  const optionsSocialNetworkIconsSize = [
    { value: '18', label: t('form.socialNetworkIconsSize.small') },
    { value: '24', label: t('form.socialNetworkIconsSize.medium') },
    { value: '30', label: t('form.socialNetworkIconsSize.large') }
  ]

  // Social Network Icons Color
  const [socialNetworkIconsColor, setSocialNetworkIconsColor] = useState(localStorage.getItem("social_network_icons_color") ? JSON.parse(localStorage.getItem("social_network_icons_color")) : { value: "official", label: t('form.socialNetworkIconsColor.official') });
  const [socialNetworkIconsColorValue, setSocialNetworkIconsColorValue] = useState(localStorage.getItem("social_network_icons_color_value") ? localStorage.getItem("social_network_icons_color_value") : "official" );

  // Handle Change Social Network Icons Color
  const handleChangeSocialNetworkIconsColor = (obj) => {
    setSocialNetworkIconsColor(obj);
    setValue("social_network_icons_color",obj);
    localStorage.setItem("social_network_icons_color",JSON.stringify(obj));
    setSocialNetworkIconsColorValue(obj.value);
    setValue("social_network_icons_color_value",obj.value);
    localStorage.setItem("social_network_icons_color_value",obj.value);
  };

  // Options Social Network Icons Color
  const optionsSocialNetworkIconsColor = [
    { value: 'official', label: t('form.socialNetworkIconsColor.official') },
    { value: 'custom', label: t('form.socialNetworkIconsColor.custom') }
  ]

  // Social Network Icons Color
  const [socialNetworkIconsCustomColor, setSocialNetworkIconsCustomColor] = useState(localStorage.getItem("social_network_icons_custom_color") ? localStorage.getItem("social_network_icons_custom_color") : "#377dff");

  // Handle Change Social Network Icons Color
  const handleChangeSocialNetworkIconsCustomColor = (value) => {
    setSocialNetworkIconsCustomColor(value);
    setValue("social_network_icons_custom_color",value);
    localStorage.setItem("social_network_icons_custom_color",value);
  };

  // Social Network Icons Style
  const [socialNetworkIconsStyle, setSocialNetworkIconsStyle] = useState(localStorage.getItem("social_network_icons_style") ? JSON.parse(localStorage.getItem("social_network_icons_style")) : { value: "background", label: t('form.socialNetworkIconsStyle.background') });
  const [socialNetworkIconsStyleValue, setSocialNetworkIconsStyleValue] = useState(localStorage.getItem("social_network_icons_style_value") ? localStorage.getItem("social_network_icons_style_value") : "background");

  // Handle Change Social Network Icons Style
  const handleChangeSocialNetworkIconsStyle = (obj) => {
    setSocialNetworkIconsStyle(obj);
    setValue("social_network_icons_style",obj);
    localStorage.setItem("social_network_icons_style",JSON.stringify(obj));
    setSocialNetworkIconsStyleValue(obj.value);
    setValue("social_network_icons_style_value",obj.value);
    localStorage.setItem("social_network_icons_style_value",obj.value);
  };

  // Options Social Network Icons Style
  const optionsSocialNetworkIconsStyle = [
    { value: 'classic', label: t('form.socialNetworkIconsStyle.classic') },
    { value: 'border', label: t('form.socialNetworkIconsStyle.border') },
    { value: 'background', label: t('form.socialNetworkIconsStyle.background') }
  ]

  // Social Network Icons Shape
  const [socialNetworkIconsShape, setSocialNetworkIconsShape] = useState(localStorage.getItem("social_network_icons_shape") ? JSON.parse(localStorage.getItem("social_network_icons_shape")) : { value: "circle", label: t('form.socialNetworkIconsShape.circle') });
  const [socialNetworkIconsShapeValue, setSocialNetworkIconsShapeValue] = useState(localStorage.getItem("social_network_icons_shape_value") ? localStorage.getItem("social_network_icons_shape_value") : "circle");

  // Handle Change Social Network Icons Shape
  const handleChangeSocialNetworkIconsShape = (obj) => {
    setSocialNetworkIconsShape(obj);
    setValue("social_network_icons_shape",obj);
    localStorage.setItem("social_network_icons_shape",JSON.stringify(obj));
    setSocialNetworkIconsShapeValue(obj.value);
    setValue("social_network_icons_shape_value",obj.value);
    localStorage.setItem("social_network_icons_shape_value",obj.value);
  };

  // Options Social Network Icons Shape
  const optionsSocialNetworkIconsShape = [
    { value: 'square', label: t('form.socialNetworkIconsShape.square') },
    { value: 'round', label: t('form.socialNetworkIconsShape.round') },
    { value: 'circle', label: t('form.socialNetworkIconsShape.circle') }
  ]

  // CTA Style
  const [CTAStyle, setCTAStyle] = useState(localStorage.getItem("cta_style") ? JSON.parse(localStorage.getItem("cta_style")) : { value: "none", label: t('form.ctaStyle.none') });
  const [CTAStyleValue, setCTAStyleValue] = useState(localStorage.getItem("cta_style_value") ? localStorage.getItem("cta_style_value") : "none");

  // Handle Change CTA Style
  const handleChangeCTAStyle = (obj) => {
    setCTAStyle(obj);
    setValue("cta_style",obj);
    localStorage.setItem("cta_style",JSON.stringify(obj));
    setCTAStyleValue(obj.value);
    setValue("cta_style_value",obj.value);
    localStorage.setItem("cta_style_value",obj.value);
    switch (obj.value) {
      case 'none':
        setCTAButtonText("");
        setValue("cta_button_text","");
        localStorage.setItem("cta_button_text","");
        setCTAButtonURL("");
        setValue("cta_button_url","");
        localStorage.setItem("cta_button_url","");
        setCTAButtonBackgroundColor("#377dff");
        setValue("cta_button_background_color","#377dff");
        localStorage.setItem("cta_button_background_color","#377dff");
        setCTAButtonTextColor("#ffffff");
        setValue("cta_button_text_color","#ffffff");
        localStorage.setItem("cta_button_text_color","#ffffff");
        break;
      case 'button':
        setCTAButtonText("");
        setValue("cta_button_text","");
        localStorage.setItem("cta_button_text","");
        setCTAButtonURL("");
        setValue("cta_button_url","");
        localStorage.setItem("cta_button_url","");
        setCTAButtonBackgroundColor("#377dff");
        setValue("cta_button_background_color","#377dff");
        localStorage.setItem("cta_button_background_color","#377dff");
        setCTAButtonTextColor("#ffffff");
        setValue("cta_button_text_color","#ffffff");
        localStorage.setItem("cta_button_text_color","#ffffff");
        break;
      case 'image':
        setCTAButtonText("");
        setValue("cta_button_text","");
        localStorage.setItem("cta_button_text","");
        setCTAButtonURL("");
        setValue("cta_button_url","");
        localStorage.setItem("cta_button_url","");
        setCTAButtonBackgroundColor("#377dff");
        setValue("cta_button_background_color","#377dff");
        localStorage.setItem("cta_button_background_color","#377dff");
        setCTAButtonTextColor("#ffffff");
        setValue("cta_button_text_color","#ffffff");
        localStorage.setItem("cta_button_text_colorcta","#ffffff");
        break;
      default:
        setCTAButtonText("");
        setValue("cta_button_text","");
        localStorage.setItem("cta_button_text","");
        setCTAButtonURL("");
        setValue("cta_button_url","");
        localStorage.setItem("cta_button_url","");
        setCTAButtonBackgroundColor("#377dff");
        setValue("cta_button_background_color","#377dff");
        localStorage.setItem("cta_button_background_color","#377dff");
        setCTAButtonTextColor("#ffffff");
        setValue("cta_button_text_color","#ffffff");
        localStorage.setItem("cta_button_text_color","#ffffff");
        break;
    }
  };

  // Options CTA Style
  const optionsCTAStyle = [
    { value: '', label: t('form.ctaStyle.none') },
    { value: 'button', label: t('form.ctaStyle.button') },
    { value: 'image', label: t('form.ctaStyle.image') }
  ]

  // CTA Button Text
  let [CTAButtonText, setCTAButtonText] = useState(localStorage.getItem("cta_button_text"));

  // Handle Change CTA Button Text
  const handleChangeCTAButtonText= ({target}) => {
    setCTAButtonText(target.value);
    setValue("cta_button_text",target.value);
    localStorage.setItem("cta_button_text",target.value);
  };

  // CTA Button URL
  let [CTAButtonURL, setCTAButtonURL] = useState(localStorage.getItem("cta_button_url"));

  // Handle Change CTA Button URL
  const handleChangeCTAButtonURL= ({target}) => {
    setCTAButtonURL(target.value);
    setValue("cta_button_url",target.value);
    localStorage.setItem("cta_button_url",target.value);
  };

  // CTA Button Background Color
  const [CTAButtonBackgroundColor, setCTAButtonBackgroundColor] = useState(localStorage.getItem("cta_button_background_color") ? localStorage.getItem("cta_button_background_color") : "#377dff");

  // Handle Change CTA Button Background Color
  const handleChangeCTAButtonBackgroundColor = (value) => {
    setCTAButtonBackgroundColor(value);
    setValue("cta_button_background_color",value);
    localStorage.setItem("cta_button_background_color",value);
  };

  // CTA Button Text Color
  const [CTAButtonTextColor, setCTAButtonTextColor] = useState(localStorage.getItem("cta_button_text_color") ? localStorage.getItem("cta_button_text_color") : "#ffffff");

  // Handle Change CTA Button Text Color
  const handleChangeCTAButtonTextColor = (value) => {
    setCTAButtonTextColor(value);
    setValue("cta_button_text_color",value);
    localStorage.setItem("cta_button_text_color",value);
  };

  // CTA Button Shape
  const [CTAButtonShape, setCTAButtonShape] = useState(localStorage.getItem("cta_button_shape") ? JSON.parse(localStorage.getItem("cta_button_shape")) : { value: "square", label: t('form.ctaButtonShape.square') });
  const [CTAButtonBorderRadius, setCTAButtonBorderRadius] = useState(localStorage.getItem("cta_button_border_radius") ? localStorage.getItem("cta_button_border_radius") : "0px");

  // Handle Change CTA Button Shape
  const handleChangeCTAButtonShape = (obj) => {
    setCTAButtonShape(obj);
    setValue("cta_button_shape",obj);
    localStorage.setItem("cta_button_shape",JSON.stringify(obj));
    switch (obj.value) {
      case 'square':
        setCTAButtonBorderRadius("0px");
        setValue("cta_button_border_radius","0px");
        localStorage.setItem("cta_button_border_radius","0px");
        break;
      case 'round':
        setCTAButtonBorderRadius("10px");
        setValue("cta_button_border_radius","10px");
        localStorage.setItem("cta_button_border_radius","10px");
        break;
      case 'circle':
        setCTAButtonBorderRadius("50px");
        setValue("cta_button_border_radius","50px");
        localStorage.setItem("cta_button_border_radius","50px");
        break;
      default:
        setCTAButtonBorderRadius("0px");
        setValue("cta_button_border_radius","0px");
        localStorage.setItem("cta_button_border_radius","0px");
        break;
    }
  };

  // Options CTA Button Shape
  const optionsCTAButtonShape = [
    { value: 'square', label: t('form.ctaButtonShape.square') },
    { value: 'round', label: t('form.ctaButtonShape.round') },
    { value: 'circle', label: t('form.ctaButtonShape.circle') }
  ]

  // CTA Image
  let [CTAImage, setCTAImage] = useState(localStorage.getItem("cta_image") ? localStorage.getItem("cta_image") : "");

  // Handle Change CTA Image
  const handleChangeCTAImage = ({target}) => {

    if (target.value) {
      var image = new Image();
      image.onload = function() {
        if (this.width > 0) {
          toast.success( t('form.ctaImage.message.success'), { position: "top-center" });     
        }
      }
      image.onerror = function() {
        toast.error( t('form.ctaImage.message.error'), { position: "top-center" });
      }
      image.src = target.value;
    }


    setCTAImage(target.value);
    setValue("cta_image",target.value);
    localStorage.setItem("cta_image",target.value);

  };

  // CTA Image URL
  let [CTAImageURL, setCTAImageURL] = useState(localStorage.getItem("cta_image_url") ? localStorage.getItem("cta_image_url") : "");

  // Handle Change CTA Image URL
  const handleChangeCTAImageURL= ({target}) => {
    setCTAImageURL(target.value);
    setValue("cta_image_url",target.value);
    localStorage.setItem("cta_image_url",target.value);
  };

  // Profile Picture
  let [profilePicture, setProfilePicture] = useState(localStorage.getItem("profile_picture") ? localStorage.getItem("profile_picture") : "");

  // Handle Change Profile Picture
  const handleChangeProfilePicture = ({target}) => {

    if (target.value) {
      var image = new Image();
      image.onload = function() {
        if (this.width > 0) {
          toast.success( t('form.profilePicture.message.success'), { position: "top-center" });     
        }
      }
      image.onerror = function() {
        toast.error( t('form.profilePicture.message.error'), { position: "top-center" });
      }
      image.src = target.value;
    }

    setProfilePicture(target.value);
    setValue("profile_picture",target.value);
    localStorage.setItem("profile_picture",target.value);

  };

  // Profile Picture Shape
  const [profilePictureShape, setProfilePictureShape] = useState(localStorage.getItem("profile_picture_shape") ? JSON.parse(localStorage.getItem("profile_picture_shape")) : { value: "square", label: t('form.profilePictureShape.square') });
  const [profilePictureBorderRadius, setProfilePictureBorderRadius] = useState(localStorage.getItem("profile_picture_border_radius") ? localStorage.getItem("profile_picture_border_radius") : "0%");

  // Handle Change Profile Picture Shape
  const handleChangeProfilePictureShape = (obj) => {
    setProfilePictureShape(obj);
    setValue("profile_picture_shape",obj);
    localStorage.setItem("profile_picture_shape",JSON.stringify(obj));
    switch (obj.value) {
      case 'square':
        setProfilePictureBorderRadius("0%");
        setValue("profile_picture_border_radius","0%");
        localStorage.setItem("profile_picture_border_radius","0%");
        break;
      case 'round':
        setProfilePictureBorderRadius("15%");
        setValue("profile_picture_border_radius","15%");
        localStorage.setItem("profile_picture_border_radius","15%");
        break;
      case 'circle':
        setProfilePictureBorderRadius("50%");
        setValue("profile_picture_border_radius","50%");
        localStorage.setItem("profile_picture_border_radius","50%");
        break;
      default:
        setProfilePictureBorderRadius("0%");
        setValue("profile_picture_border_radius","0%");
        localStorage.setItem("profile_picture_border_radius","0%");
        break;
    }
  };

  // Options Profile Picture Shape
  const optionsProfilePictureShape = [
    { value: 'square', label: t('form.profilePictureShape.square') },
    { value: 'round', label: t('form.profilePictureShape.round') },
    { value: 'circle', label: t('form.profilePictureShape.circle') }
  ]

  // Company Logo
  let [companyLogo, setCompanyLogo] = useState(localStorage.getItem("company_logo") ? localStorage.getItem("company_logo") : "");

  // Handle Change Company Logo
  const handleChangeCompanyLogo = ({target}) => {

    if (target.value) {
      var image = new Image();
      image.onload = function() {
        if (this.width > 0) {
          toast.success( t('form.companyLogo.message.success'), { position: "top-center" });     
        }
      }
      image.onerror = function() {
        toast.error( t('form.companyLogo.message.error'), { position: "top-center" });
      }
      image.src = target.value;
    }

    setCompanyLogo(target.value);
    setValue("company_logo",target.value);
    localStorage.setItem("company_logo",target.value);

  };

  // Handle Click Copy Signature
  const handleClickCopySignature = () => {

    const { ClipboardItem } = window;
    
    var type = "text/html";
    var blob = new Blob([signatureHTML], { type });
    var data = [new ClipboardItem({ [type]: blob })];
    
    navigator.clipboard.write(data).then(() => {
      toast.success( t('copySignature.message.success'), { position: "top-center" });
    },() => {
      toast.error( t('copySignature.message.error'), { position: "top-center" });
    });    

    handleErrors();

   
    /*

    var container = document.createElement('div');
    container.innerHTML = signatureHTML;

    // Hide element
    container.style.position = 'fixed';
    container.style.pointerEvents = 'none';
    container.style.opacity = 0;
    document.body.appendChild(container);
    var range = document.createRange();
    var selection = window.getSelection();
    range.selectNode(container);
    selection.removeAllRanges();
    selection.addRange(range);
    var result = document.execCommand('copy');
    if (result) {
      toast.success( t('copySignature.message.success'), { position: "top-center" });
    } else {
      toast.error( t('copySignature.message.error'), { position: "top-center" });
    }
    document.body.removeChild(container);

    handleErrors();

    */

  }

  // Handle Click Copy Signature Source Code
  const handleClickCopySignatureSourceCode = () => {
    navigator.clipboard.writeText(signatureHTML).then(() => {
      toast.success( t('copySignatureSourceCode.message.success'), { position: "top-center" });
    },() => {
      toast.error( t('copySignatureSourceCode.message.error'), { position: "top-center" });
    });    

    handleErrors();

  }

  // Handle Click Reset Input Fields
  const handleClickResetInputFields = () => {

    setFirstName("");
    setValue("first_name","");
    localStorage.setItem("first_name","");
    setLastName("");
    setValue("last_name","");
    localStorage.setItem("last_name","");
    setJobTitle("");
    setValue("job_title","");
    localStorage.setItem("job_title","");
    setDepartment("");
    setValue("department","");
    localStorage.setItem("department","");
    setEnterprise("");
    setValue("enterprise","");
    localStorage.setItem("enterprise",""); 
    setCustomField("");
    setValue("custom_field","");
    localStorage.setItem("custom_field","");
    setOfficeTelephoneNumber("");
    setValue("office_telephone_number","");
    localStorage.setItem("office_telephone_number","");
    setMobileTelephoneNumber("");
    setValue("mobile_telephone_number","");
    localStorage.setItem("mobile_telephone_number","");
    setEmail("");
    setValue("email","");
    localStorage.setItem("email","");
    setWebsiteURL("");
    setValue("website_url","");
    localStorage.setItem("website_url","");
    setAddressStreetNumber("");
    setValue("address_street_number","");
    localStorage.setItem("address_street_number","");
    setAddressStreet("");
    setValue("address_street","");
    localStorage.setItem("address_street","");
    setAddressPostalCode("");
    setValue("address_postal_code","");
    localStorage.setItem("address_postal_code","");
    setAddressCity("");
    setValue("address_city","");
    localStorage.setItem("address_city","");
    setAddressCountry("");
    setValue("address_country","");
    localStorage.setItem("address_country","");
    setAddressDirectionURL("");
    setValue("address_direction_url","");
    localStorage.setItem("address_direction_url","");
    setSocialNetworks("");
    setValue("social_networks","");
    localStorage.setItem("social_networks","");
    setCTAButtonText("");
    setValue("cta_button_text","");
    localStorage.setItem("cta_button_text","");
    setCTAButtonURL("");
    setValue("cta_button_url","");
    localStorage.setItem("cta_button_url","");
    setCTAImage("");
    setValue("cta_image","");
    localStorage.setItem("cta_image","");
    setCTAImageURL("");
    setValue("cta_image_url","");
    localStorage.setItem("cta_image_url","");
    setProfilePicture("");
    setValue("profile_picture","");
    localStorage.setItem("profile_picture","");
    setCompanyLogo("");
    setValue("company_logo","");
    localStorage.setItem("company_logo","");

    reset();
    toast.success( t('resetInputFields.message.success'), { position: "top-center" });

  }

  // Handle Click Copy Signature
  const handleErrors = () => {

    trigger();

    if(errors){

      Object.entries(errors).forEach((error, index) => {
        toast.warn(error[1].message, { position: "top-center" });
      })
    }

  }

  const selectCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border:  "none",
      color: state.isFocused ? 'blue' : 'red'
    }),
    menu: (provided, state) => ({
      ...provided,
      border: ".0625rem solid rgba(33,50,91,.1)",
      boxShadow: "none"
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#1e2022',
    }),
    option: (provided, state) => ({
       ...provided,
       fontSize: "0.875rem"
    }),
  }

  const selectTheme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#f3f3f3",
      primary: "#377dff",
      danger: '#ed4c78',
      dangerLight: '#f5ca99',
      neutral0: '#ffffff',
      neutral5: '#f7faff',
      neutral10: '#f8fafd',
      neutral20: "rgba(33, 50, 91, 0.1)",
      neutral30: '#bdc5d1',
      neutral40: '#97a4af',
      neutral50: '#8c98a4',
      neutral60: '#677788',
      neutral70: '#71869d',
      neutral80: '#1e2022',
      neutral90: '#21325b',
    },
    // Other options you can use
    border: 0,
    borderRadius: 4,
    boxShadow: 'none',
  });

  // Update Simulator
  const updateSignature = value => {

    // Default Signature
    var defaultSignature = true;
    
    // Fields
    var fields = [
      firstName,
      lastName,
      jobTitle,
      department,
      enterprise,
      customField,
      officeTelephoneNumber,
      mobileTelephoneNumber,
      email,
      websiteURL,
      addressStreetNumber,
      addressStreet,
      addressPostalCode,
      addressCity,
      addressCountry,
      addressDirectionURL,
      socialNetworks,
      CTAButtonText,
      CTAButtonURL,
      CTAImage,
      CTAImageURL,
      profilePicture,
      companyLogo,
    ];

    // Check if fields not empty
    fields.forEach(field => {
      if(field && field.length > 0){
        defaultSignature = false;
      }
    });

    // Default Signature
    if(defaultSignature === true){
      firstName = "John";
      lastName = "Doe";
      jobTitle = "John Doe";
      department = "Marketing";
      enterprise = "La Small Agency";
      customField = "He/Him";
      officeTelephoneNumber = "+33 4 04 04 04 04";
      mobileTelephoneNumber = "+33 6 06 06 06 06";
      email = "johndoe@lasmallagency.com";
      websiteURL = "https://lasmallagency.com";
      addressStreetNumber = "17";
      addressStreet = " rue Anatole France";
      addressPostalCode = "42300";
      addressCity = "Roanne";
      addressCountry = "France";
      addressDirectionURL = "https://www.google.com/maps?daddr=46.034788515167484,4.070388354573682";
      socialNetworks = JSON.parse('[{"type":{"value":"linkedIn","label":"LinkedIn"},"text":"lasmallagency"},{"type":{"value":"facebook","label":"Facebook"},"text":"lasmallagency"},{"type":{"value":"instagram","label":"Instagram"},"text":"lasmallagency"},{"type":{"value":"twitter","label":"Twitter"},"text":"lasmallagency"}]');
      CTAButtonText = t('preview.ctaButton.text');
      CTAButtonURL = "https://lasmallagency.com";
      CTAImage = defaultCTAImage;
      CTAImageURL = "https://lasmallagency.com";
      profilePicture = defaultProfilePicture;
      companyLogo = defaultCompanyLogo;
    }

    // Signature Details
    const signatureDetails = (
      <>
        <h3 color={textColor} style={{ margin: '0px', fontSize: fontSizeTitle, color: textColor }}>
          {firstName &&
            <span>{firstName}</span>
          }
          {firstName && lastName &&
            <span>&nbsp;</span>
          }
          {lastName &&
            <span>{lastName}</span>
          }
        </h3>
        {jobTitle &&
          <p color={textColor} fontSize={fontSizeValue} style={{ margin: '0px', fontSize: fontSizeText, lineHeight: '22px', fontWeight: '500', color: textColor }}><span>{jobTitle}</span></p>
        }
        <p color={textColor} fontSize={fontSizeValue} style={{ margin: '0px', fontSize: fontSizeText, lineHeight: '22px', fontWeight: '500', color: textColor }}>
          {department &&
            <span>{department}</span>
          }
          {department && enterprise &&
            <span>&nbsp;|&nbsp;</span>
          }
          {enterprise &&
            <span>{enterprise}</span>
          }
        </p>
        {customField &&
          <p color={textColor} fontSize={fontSizeValue} style={{ margin: '0px', fontSize: fontSizeText, lineHeight: '22px', fontWeight: '500', color: textColor }}><span>{customField}</span></p>
        }
      </>
    );

    // Signature Contacts
    const signatureContacts = (
      <>
        <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
          <tbody>
            {(officeTelephoneNumber || mobileTelephoneNumber) &&
              <tr height="25" style={{ verticalAlign: 'middle' }}>
                <td width="30" style={{ verticalAlign: 'middle' }}>
                  <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                    <tbody>
                      <tr>
                        <td style={{ verticalAlign: 'bottom' }}>
                          <>
                            {contactIconsStyleValue === 'classic' ?
                              <span color={contactIconsCustomColor} width={contactIconsSizeValue} style={{ display: 'block', backgroundColor: contactIconsCustomColor }}>
                                <img src={origin + "/files/resources/email-signature-generator/icons/contacts/" + contactIconsStyleValue + "/telephone.png"} alt={t('preview.telephone.img.alt')} color={contactIconsCustomColor} width={contactIconsSizeValue} style={{ display: 'block', backgroundColor: contactIconsCustomColor }}/>
                              </span>
                            : 
                              <span color={contactIconsCustomColor} width={contactIconsSizeValue} style={{ display: 'block', backgroundColor: contactIconsCustomColor }}>
                                <img src={origin + "/files/resources/email-signature-generator/icons/contacts/" + contactIconsStyleValue + "/" + contactIconsShapeValue + "/telephone.png"} alt={t('preview.telephone.img.alt')} color={contactIconsCustomColor} width={contactIconsSizeValue} style={{ display: 'block', backgroundColor: contactIconsCustomColor }}/>
                              </span>
                            }
                          </>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style={{ padding: '0px', color: textColor }}>
                  {officeTelephoneNumber &&
                    <a href={"tel:" + officeTelephoneNumber} color={textColor} style={{ textDecoration: 'none', fontSize: fontSizeText, color: textColor }}>
                      <span>{officeTelephoneNumber}</span>
                    </a>
                  }
                  {officeTelephoneNumber && mobileTelephoneNumber &&
                    <span>&nbsp;|&nbsp;</span>
                  }
                  {mobileTelephoneNumber &&
                    <a href={"tel:" + mobileTelephoneNumber} color={textColor} style={{ textDecoration: 'none', fontSize: fontSizeText, color: textColor }}>
                      <span>{mobileTelephoneNumber}</span>
                    </a>
                  }
                </td>
              </tr>
            }
            {email &&
              <tr height="25" style={{ verticalAlign: 'middle' }}>
                <td width="30" style={{ verticalAlign: 'middle' }}>
                  <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                    <tbody>
                      <tr>
                        <td style={{ verticalAlign: 'bottom' }}>
                          <>
                            {contactIconsStyleValue === 'classic' ?
                              <span color={contactIconsCustomColor} width={contactIconsSizeValue} style={{ display: 'block', backgroundColor: contactIconsCustomColor }}>
                                <img src={origin + "/files/resources/email-signature-generator/icons/contacts/" + contactIconsStyleValue + "/email.png"} alt={t('preview.email.img.alt')} color={contactIconsCustomColor} width={contactIconsSizeValue} style={{ display: 'block', backgroundColor: contactIconsCustomColor }}/>
                              </span>
                            : 
                              <span color={contactIconsCustomColor} width={contactIconsSizeValue} style={{ display: 'block', backgroundColor: contactIconsCustomColor }}>
                                <img src={origin + "/files/resources/email-signature-generator/icons/contacts/" + contactIconsStyleValue + "/" + contactIconsShapeValue + "/email.png"} alt={t('preview.email.img.alt')} color={contactIconsCustomColor} width={contactIconsSizeValue} style={{ display: 'block', backgroundColor: contactIconsCustomColor }}/>
                              </span>
                            }
                          </>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style={{ padding: '0px' }}>
                  <a href={"mailto:" + email} color={textColor} style={{ textDecoration: 'none', fontSize: fontSizeText, color: textColor }}>
                    <span>{email}</span>
                  </a>
                </td>
              </tr>
            }
            {websiteURL &&
              <tr height="25" style={{ verticalAlign: 'middle' }}>
                <td width="30" style={{ verticalAlign: 'middle' }}>
                  <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                    <tbody>
                      <tr>
                        <td style={{ verticalAlign: 'bottom' }}>
                          <>
                            {contactIconsStyleValue === 'classic' ?
                              <span color={contactIconsCustomColor} width={contactIconsSizeValue} style={{ display: 'block', backgroundColor: contactIconsCustomColor }}>
                                <img src={origin + "/files/resources/email-signature-generator/icons/contacts/" + contactIconsStyleValue + "/website.png"} alt={t('preview.website.img.alt')} color={contactIconsCustomColor} width={contactIconsSizeValue} style={{ display: 'block', backgroundColor: contactIconsCustomColor }}/>
                              </span>
                            : 
                              <span color={contactIconsCustomColor} width={contactIconsSizeValue} style={{ display: 'block', backgroundColor: contactIconsCustomColor }}>
                                <img src={origin + "/files/resources/email-signature-generator/icons/contacts/" + contactIconsStyleValue + "/" + contactIconsShapeValue + "/website.png"} alt={t('preview.website.img.alt')} color={contactIconsCustomColor} width={contactIconsSizeValue} style={{ display: 'block', backgroundColor: contactIconsCustomColor }}/>
                              </span>
                            }
                          </>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style={{ padding: '0px' }}>
                  <a href={websiteURL} target="_blank" rel="noopener noreferrer" color={textColor} style={{ textDecoration: 'none', fontSize: fontSizeText, color: textColor }}>
                    <span>{websiteURL}</span>
                  </a>
                </td>
              </tr>
            }
            {(addressStreetNumber || addressStreet || addressPostalCode || addressCity || addressCountry) && 
              <>
                <tr height="25" style={{ verticalAlign: 'middle' }}>
                  <td width="30" style={{ verticalAlign: 'middle' }}>
                    <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                      <tbody>
                        <tr>
                          <td style={{ verticalAlign: 'bottom' }}>
                            <>
                              {contactIconsStyleValue === 'classic' ?
                                <span color={contactIconsCustomColor} width={contactIconsSizeValue} style={{ display: 'block', backgroundColor: contactIconsCustomColor }}>
                                  <img src={origin + "/files/resources/email-signature-generator/icons/contacts/" + contactIconsStyleValue + "/address.png"} alt={t('preview.address.img.alt')} color={contactIconsCustomColor} width={contactIconsSizeValue} style={{ display: 'block', backgroundColor: contactIconsCustomColor }}/>
                                </span>
                              : 
                                <span color={contactIconsCustomColor} width={contactIconsSizeValue} style={{ display: 'block', backgroundColor: contactIconsCustomColor }}>
                                  <img src={origin + "/files/resources/email-signature-generator/icons/contacts/" + contactIconsStyleValue + "/" + contactIconsShapeValue + "/address.png"} alt={t('preview.address.img.alt')} color={contactIconsCustomColor} width={contactIconsSizeValue} style={{ display: 'block', backgroundColor: contactIconsCustomColor }}/>
                                </span>
                              }
                            </>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style={{ padding: '0px' }}>
                    {addressDirectionURL ?
                      <a href={addressDirectionURL} target="_blank" rel="noopener noreferrer" color={textColor} style={{ textDecoration: 'none', fontSize: fontSizeText, color: textColor }}>
                        <span color={textColor} style={{ textDecoration: 'none', fontSize: fontSizeText, color: textColor }}>
                          {addressStreetNumber && 
                            <>
                            {addressStreetNumber}
                            </>
                          }
                          {addressStreet && 
                            <>
                            {addressStreet}
                            </>
                          }
                          {addressPostalCode && 
                            <>
                            , {addressPostalCode}
                            </>
                          }
                          {addressCity && 
                            <>
                            , {addressCity}
                            </>
                          }
                          {addressCountry && 
                            <>
                            , {addressCountry}
                            </>
                          }
                        </span>
                      </a>
                    : 
                      <span color={textColor} style={{ textDecoration: 'none', fontSize: fontSizeText, color: textColor }}>
                        {addressStreetNumber && 
                          <>
                          {addressStreetNumber}
                          </>
                        }
                        {addressStreet && 
                          <>
                          {addressStreet}
                          </>
                        }
                        {addressPostalCode && 
                          <>
                          , {addressPostalCode}
                          </>
                        }
                        {addressCity && 
                          <>
                          , {addressCity}
                          </>
                        }
                        {addressCountry && 
                          <>
                          , {addressCountry}
                          </>
                        }
                      </span>
                    }
                  </td>
                </tr>
              </>
            }
          </tbody>
        </table>
      </>
    );

    // Signature Social Links
    const signatureSocialLinks = (
      <>
        <table cellPadding="0" cellSpacing="0" style={{ display: 'inline-block', verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
          <tbody>
            <tr style={{ textAlign: 'center' }}>

            {socialNetworks.length > 0 && (
              <>
                {socialNetworks.map((socialNetwork, index) => (
                  <div key={index}>
                    {socialNetworkTypes.length > 0 && (
                      <>
                        {socialNetworkTypes.map((socialNetworkType, indexSocialNetworkType) => (
                          <div key={indexSocialNetworkType}>
                            {socialNetwork.type.value === socialNetworkType.value &&
                                <>
                                <td>
                                  {socialNetworkIconsColorValue === 'custom' ?
                                    <>
                                      {socialNetworkIconsStyleValue === 'classic' ?
                                        <a href={socialNetworkType.url.value + socialNetwork.text} target="_blank" rel="noopener noreferrer" color={socialNetworkIconsCustomColor} style={{ display: 'inline-block', padding: '0px', backgroundColor: socialNetworkIconsCustomColor }}>
                                          <img src={origin + "/files/resources/email-signature-generator/icons/social-networks/" + socialNetworkIconsColorValue + "/" + socialNetworkIconsStyleValue + "/" + socialNetworkType.value + ".png"} alt={socialNetworkType.label} color={socialNetworkIconsCustomColor} height={socialNetworkIconsSizeValue} style={{ display: 'block', maxWidth: '135px', backgroundColor: socialNetworkIconsCustomColor }}/>
                                        </a>
                                      : 
                                        <a href={socialNetworkType.url.value + socialNetwork.text} target="_blank" rel="noopener noreferrer" color={socialNetworkIconsCustomColor} style={{ display: 'inline-block', padding: '0px', backgroundColor: socialNetworkIconsCustomColor }}>
                                          <img src={origin + "/files/resources/email-signature-generator/icons/social-networks/" + socialNetworkIconsColorValue + "/" + socialNetworkIconsStyleValue + "/" + socialNetworkIconsShapeValue + "/" + socialNetworkType.value + ".png"} alt={socialNetworkType.label} color={socialNetworkIconsCustomColor} height={socialNetworkIconsSizeValue} style={{ display: 'block', maxWidth: '135px', backgroundColor: socialNetworkIconsCustomColor }}/>
                                        </a>
                                      }
                                    </>
                                  : 
                                    <>
                                      {socialNetworkIconsStyleValue === 'classic' ?
                                        <a href={socialNetworkType.url.value + socialNetwork.text} target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', padding: '0px' }}>
                                          <img src={origin + "/files/resources/email-signature-generator/icons/social-networks/" + socialNetworkIconsColorValue + "/" + socialNetworkIconsStyleValue + "/" + socialNetworkType.value + ".png"} alt={socialNetworkType.label} height={socialNetworkIconsSizeValue} style={{ display: 'block', maxWidth: '135px' }}/>
                                        </a>
                                      : 
                                        <a href={socialNetworkType.url.value + socialNetwork.text} target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', padding: '0px' }}>
                                          <img src={origin + "/files/resources/email-signature-generator/icons/social-networks/" + socialNetworkIconsColorValue + "/" + socialNetworkIconsStyleValue + "/" + socialNetworkIconsShapeValue + "/" + socialNetworkType.value + ".png"} alt={socialNetworkType.label} height={socialNetworkIconsSizeValue} style={{ display: 'block', maxWidth: '135px' }}/>
                                        </a>
                                      }
                                    </>
                                  }
                                </td>
                                <td width="5">
                                  <div></div>
                                </td>
                                </>
                              }
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                ))}
              </>
            )}
            </tr>
          </tbody>
        </table>
      </>
    );

    // Signature Profile Picture
    const signatureProfilePicture = (
      <>
        {profilePicture &&
          <>
            {(template === '1' || template === '2') &&
              <>
                <tr>
                  <td style={{ textAlign: 'center' }}>
                    <img src={profilePicture} alt={t('preview.profilePicture.img.alt')} role="presentation" width="130" style={{ maxWidth: '130px', display: 'block', borderRadius: profilePictureBorderRadius }}/>
                  </td>
                </tr>
                <tr>
                  <td height="30"></td>
                </tr>
              </>
            }
            {(template === '3') &&
              <>
                <td style={{ textAlign: 'left' }}>
                  <span style={{ display: 'inline-block', marginRight: '20px', textAlign: 'left', width: '100%' }}>
                    <img src={profilePicture} alt={t('preview.profilePicture.img.alt')} role="presentation" width="130" style={{ maxWidth: '130px', display: 'inline', textAlign: 'left', borderRadius: profilePictureBorderRadius }}/>
                  </span>
                </td>
              </>
            }
            {(template === '4') &&
              <>
                <td style={{ textAlign: 'right' }}>
                  <span style={{ display: 'inline-block', marginRight: '20px', textAlign: 'right', width: '100%' }}>
                    <img src={profilePicture} alt={t('preview.profilePicture.img.alt')} role="presentation" width="130" style={{ maxWidth: '130px', display: 'inline', textAlign: 'right', borderRadius: profilePictureBorderRadius }}/>
                  </span>
                </td>
              </>
            }
            {(template === '5') &&
              <>
                <td width="150" style={{ verticalAlign: 'middle' }}>
                  <span style={{ display: 'block', marginRight: '20px' }}>
                    <img src={profilePicture} alt={t('preview.profilePicture.img.alt')} role="presentation" width="130" style={{ maxWidth: '130px', borderRadius: profilePictureBorderRadius }}/>
                  </span>
                </td>
              </>
            }
            {(template === '6') &&
              <>
                <td style={{ textAlign: 'center' }}>
                  <img src={profilePicture} alt={t('preview.profilePicture.img.alt')} role="presentation" width="130" style={{ maxWidth: '130px', display: 'inline-block', borderRadius: profilePictureBorderRadius }}/>
                </td>
              </>
            }
          </>
        }
      </>
    );

    // Signature Company Logo
    const signatureCompanyLogo = (
      <>
        {companyLogo &&
          <>
            {(template === '1' || template === '2') &&
              <>
                <tr>
                  <td style={{ textAlign: 'center' }}>
                    <img src={companyLogo} alt={t('preview.companyLogo.img.alt')} role="presentation" width="130" style={{ maxWidth: '130px', display: 'block' }}/>
                  </td>
                </tr>
                <tr>
                  <td height="30"></td>
                </tr>
              </>
            }
            {(template === '3' || template === '4') &&
              <>
                <td style={{ verticalAlign: 'top' }}>
                  <img src={companyLogo} alt={t('preview.companyLogo.img.alt')} role="presentation" width="130" style={{ maxWidth: '130px', display: 'inline', textAlign: 'left' }}/>
                </td>
              </>
            }
            {(template === '5') &&
              <>
                <td style={{ verticalAlign: 'top' }}>
                  <img src={companyLogo} alt={t('preview.companyLogo.img.alt')} role="presentation" width="130" style={{ maxWidth: '130px', display: 'inline-block' }}/>
                </td>
              </>
            }
            {(template === '6') &&
              <>
                <td>
                  <img src={companyLogo} alt={t('preview.companyLogo.img.alt')} role="presentation" width="130" style={{ maxWidth: '130px', display: 'inline-block' }}/>
                </td>
              </>
            }
          </>
        }
      </>
    );

    // Signature CTA Button
    const signatureCTAButton = (
      <>
        {CTAStyleValue === 'button' && CTAButtonText &&
          <>
            {(template === '1' || template === '2') &&
              <>
                <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                  <tbody>
                    <tr>
                      <td height="30"></td>
                    </tr>
                  </tbody>
                </table>
                <span style={{ display: 'block', textAlign: 'left' }}>
                  <a href={CTAButtonURL} target="_blank" rel="noopener noreferrer" color={CTAButtonBackgroundColor} style={{ display: 'inline-block', textDecoration: 'none', textAlign: 'center', borderWidth: '6px 12px', borderStyle: 'solid', lineHeight: '40px', fontWeight: '700', fontSize: fontSizeText, borderColor: CTAButtonBackgroundColor, backgroundColor: CTAButtonBackgroundColor, color: CTAButtonTextColor, borderRadius: CTAButtonBorderRadius }}>{CTAButtonText}</a>
                </span>
              </>
            }
            {(template === '3' || template === '4' || template === '5') &&
              <>
                <td style={{ textAlign: 'right' }}>
                  <span style={{ display: 'block', textAlign: 'right' }}>
                    <a href={CTAButtonURL} target="_blank" rel="noopener noreferrer" color={CTAButtonBackgroundColor} style={{ display: 'inline-block', textDecoration: 'none', textAlign: 'center', borderWidth: '6px 12px', borderStyle: 'solid', lineHeight: '40px', fontWeight: '700', fontSize: fontSizeText, borderColor: CTAButtonBackgroundColor, backgroundColor: CTAButtonBackgroundColor, color: CTAButtonTextColor, borderRadius: CTAButtonBorderRadius }}>{CTAButtonText}</a>
                  </span>
                </td>
              </>
            }
            {(template === '6') &&
              <>
                <td style={{ textAlign: 'center' }}>
                  <span style={{ display: 'block', textAlign: 'center' }}>
                    <a href={CTAButtonURL} target="_blank" rel="noopener noreferrer" color={CTAButtonBackgroundColor} style={{ display: 'inline-block', textDecoration: 'none', textAlign: 'center', borderWidth: '6px 12px', borderStyle: 'solid', lineHeight: '40px', fontWeight: '700', fontSize: fontSizeText, borderColor: CTAButtonBackgroundColor, backgroundColor: CTAButtonBackgroundColor, color: CTAButtonTextColor, borderRadius: CTAButtonBorderRadius }}>{CTAButtonText}</a>
                  </span>
                </td>
              </>
            }
          </>
        }
      </>
    );

    // Signature CTA Image
    const signatureCTAImage = (
      <>
        {CTAStyleValue === 'image' && CTAImage &&
          <>
            {(template === '1' || template === '2' || template === '3' || template === '4' || template === '5' || template === '6') &&
              <>
                <td style={{ textAlign: 'center' }}>
                  <span style={{ display: 'block', textAlign: 'center' }}>
                    <a href={CTAImageURL} target="_blank" rel="noopener noreferrer">
                      <img src={CTAImage} alt={t('preview.ctaImage.img.alt')} role="presentation" style={{ textDecoration: 'none', width: '100%', maxWidth: '700px' }}/>
                    </a>
                  </span>
                </td>
              </>
            }
          </>
        }
      </>
    );

    // Signature Link
    const signatureLink = (
      <>
        {createdWith === 'checked' &&
          <>
            {(template === '1' || template === '2' || template === '3' || template === '4' || template === '5' || template === '6') &&
              <>
                <a href={origin + "/resource/email-signature-generator/"} target="_blank" rel="noopener noreferrer" style={{ display: 'block', fontSize: fontSizeText, color: textColor }}>{t('preview.createdWith', { title: data.site.siteMetadata.title })}</a>
              </>
            }
          </>
        }
      </>
    );

    const signature = (
      <>
        {template === '1' &&
          <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
            <tbody>
              <tr>
                <td>
                  <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                    <tbody>
                      <tr>
                        <td style={{ verticalAlign: 'top' }}>
                          <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                            <tbody>
                              {signatureProfilePicture}
                              {signatureCompanyLogo}
                              <tr>
                                <td style={{ textAlign: 'center' }}>
                                  {signatureSocialLinks}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width="45">
                          <div></div>
                        </td>
                        <td style={{ verticalAlign: 'middle', padding: '0px'}}>
                          {signatureDetails}
                          <table cellPadding="0" cellSpacing="0" style={{ width: '100%', verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                            <tbody>
                              <tr>
                                <td height="30"></td>
                              </tr>
                              <tr>
                                <td color={themeColor} direction="horizontal" height="1" style={{ display: 'block', width: '100%', borderLeft: 'none', borderBottom: '1px solid ' + themeColor }}></td>
                              </tr>
                              <tr>
                                <td height="30"></td>
                              </tr>
                            </tbody>
                          </table>
                          {signatureContacts}
                          {CTAStyleValue === 'none' &&
                            <>
                              {createdWith === 'checked' &&
                                <>
                                  <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                                    <tbody>
                                      <tr>
                                        <td height="30"></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {signatureLink}
                                </>
                              }
                            </>
                          }
                          {CTAStyleValue === 'button' &&
                            <>
                              {signatureCTAButton}
                              {createdWith === 'checked' &&
                                <>
                                  <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                                    <tbody>
                                      <tr>
                                        <td height="30"></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {signatureLink}
                                </>
                              }
                            </>
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              {CTAStyleValue === 'image' &&
                <>
                  <tr>
                    <td height="30"></td>
                  </tr>
                  <tr>
                    {signatureCTAImage}
                  </tr>
                  {createdWith === 'checked' &&
                    <>
                      <tr>
                        <td height="30"></td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'center' }}>
                          {signatureLink}
                        </td>
                      </tr>
                    </>
                  }
                </>
              }
            </tbody>
          </table>
        }
        {template === '2' &&
          <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
            <tbody>
              <tr>
                <td>
                  <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                    <tbody>
                      <tr>
                        <td style={{ verticalAlign: 'middle', padding: '0px'}}>
                          {signatureDetails}
                          <table cellPadding="0" cellSpacing="0" style={{ width: '100%', verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                            <tbody>
                              <tr>
                                <td height="30"></td>
                              </tr>
                              <tr>
                                <td color={themeColor} direction="horizontal" height="1" style={{ display: 'block', width: '100%', borderLeft: 'none', borderBottom: '1px solid ' + themeColor }}></td>
                              </tr>
                              <tr>
                                <td height="30"></td>
                              </tr>
                            </tbody>
                          </table>
                          {signatureContacts}
                          {CTAStyleValue === 'none' &&
                            <>
                              {createdWith === 'checked' &&
                                <>
                                  <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                                    <tbody>
                                      <tr>
                                        <td height="30"></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {signatureLink}
                                </>
                              }
                            </>
                          }
                          {CTAStyleValue === 'button' &&
                            <>
                              {signatureCTAButton}
                              {createdWith === 'checked' &&
                                <>
                                  <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                                    <tbody>
                                      <tr>
                                        <td height="30"></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {signatureLink}
                                </>
                              }
                            </>
                          }
                        </td>
                        <td width="45">
                          <div></div>
                        </td>
                        <td style={{ verticalAlign: 'top' }}>
                          <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                            <tbody>
                              {signatureProfilePicture}
                              {signatureCompanyLogo}
                              <tr>
                                <td style={{ textAlign: 'center' }}>
                                  {signatureSocialLinks}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              {CTAStyleValue === 'image' &&
                <>
                  <tr>
                    <td height="30"></td>
                  </tr>
                  <tr>
                    {signatureCTAImage}
                  </tr>
                  {createdWith === 'checked' &&
                    <>
                      <tr>
                        <td height="30"></td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'center' }}>
                          {signatureLink}                      
                        </td>
                      </tr>
                    </>
                  }
                </>
              }
            </tbody>
          </table>
        }
        {template === '3' &&
          <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
            <tbody>
              <tr>
                <td>
                  <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', width: '100%', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                    <tbody>
                      <tr>
                        {signatureProfilePicture}
                        <td>
                          {signatureDetails}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height="30"></td>
              </tr>
              <tr>
                <td color={themeColor} direction="horizontal" height="1" style={{ display: 'block', width: '100%', borderLeft: 'none', borderBottom: '1px solid ' + themeColor }}></td>
              </tr>
              <tr>
                <td height="30"></td>
              </tr>
              <tr>
                <td>
                  {signatureContacts}
                </td>
              </tr>
              <tr>
                <td height="30"></td>
              </tr>
              <tr>
                <td color={themeColor} direction="horizontal" height="1" style={{ display: 'block', width: '100%', borderLeft: 'none', borderBottom: '1px solid ' + themeColor }}></td>
              </tr>
              <tr>
                <td height="30"></td>
              </tr>
              <tr>
                <td>
                  <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', width: '100%', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                    <tbody>
                      <tr>
                        {signatureCompanyLogo}
                        <td style={{ verticalAlign: 'top', textAlign: 'right' }}>
                          {signatureSocialLinks}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              {CTAStyleValue === 'none' &&
                <>
                  {createdWith === 'checked' &&
                    <>
                      <tr>
                        <td>
                          <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', width: '100%', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                            <tbody>
                              <tr>
                                <td height="15"></td>
                              </tr>
                              <tr>
                                <td>
                                  {signatureLink}                      
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </>
                  }
                </>
              }
              {CTAStyleValue === 'button' &&
                <>
                  <tr>
                    <td>
                      <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', width: '100%', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                        <tbody>
                          <tr>
                            <td height="15"></td>
                          </tr>
                          <tr>
                            {createdWith === 'checked' &&
                              <>
                                <td>
                                  {signatureLink}                      
                                </td>
                                <td width="15"></td>
                              </>
                            }
                            {signatureCTAButton}
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </>
              }
              {CTAStyleValue === 'image' &&
                <>
                  <tr>
                    <td height="30"></td>
                  </tr>
                  <tr>
                    {signatureCTAImage}
                  </tr>
                  {createdWith === 'checked' &&
                    <>
                      <tr>
                        <td height="30"></td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'center' }}>
                          {signatureLink}                      
                        </td>
                      </tr>
                    </>
                  }
                </>
              }
            </tbody>
          </table>
        }
        {template === '4' &&
          <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
            <tbody>
              <tr>
                <td>
                  <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', width: '100%', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                    <tbody>
                      <tr>
                        <td>
                          {signatureDetails}
                        </td>
                        {signatureProfilePicture}
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height="30"></td>
              </tr>
              <tr>
                <td color={themeColor} direction="horizontal" height="1" style={{ display: 'block', width: '100%', borderLeft: 'none', borderBottom: '1px solid ' + themeColor }}></td>
              </tr>
              <tr>
                <td height="30"></td>
              </tr>
              <tr>
                <td>
                  {signatureContacts}
                </td>
              </tr>
              <tr>
                <td height="30"></td>
              </tr>
              <tr>
                <td color={themeColor} direction="horizontal" height="1" style={{ display: 'block', width: '100%', borderLeft: 'none', borderBottom: '1px solid ' + themeColor }}></td>
              </tr>
              <tr>
                <td height="30"></td>
              </tr>
              <tr>
                <td>
                  <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', width: '100%', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                    <tbody>
                      <tr>
                        {signatureCompanyLogo}
                        <td style={{ verticalAlign: 'top', textAlign: 'right' }}>
                          {signatureSocialLinks}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              {CTAStyleValue === 'none' &&
                <>
                  {createdWith === 'checked' &&
                    <>
                      <tr>
                        <td>
                          <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', width: '100%', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                            <tbody>
                              <tr>
                                <td height="15"></td>
                              </tr>
                              <tr>
                                <td>
                                  {signatureLink}                      
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </>
                  }
                </>
              }
              {CTAStyleValue === 'button' &&
                <>
                  <tr>
                    <td>
                      <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', width: '100%', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                        <tbody>
                          <tr>
                            <td height="15"></td>
                          </tr>
                          <tr>
                            {createdWith === 'checked' &&
                              <>
                                <td>
                                  {signatureLink}                      
                                </td>
                                <td width="15"></td>
                              </>
                            }
                            {signatureCTAButton}
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </>
              }
              {CTAStyleValue === 'image' &&
                <>
                  <tr>
                    <td height="30"></td>
                  </tr>
                  <tr>
                    {signatureCTAImage}
                  </tr>
                  {createdWith === 'checked' &&
                    <>
                      <tr>
                        <td height="30"></td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'center' }}>
                          {signatureLink}                      
                        </td>
                      </tr>
                    </>
                  }
                </>
              }
            </tbody>
          </table>
        }
        {template === '5' &&
          <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
            <tbody>
              <tr>
                <td>
                  <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', width: '100%', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                    <tbody>
                      <tr>
                        {signatureProfilePicture}
                        <td style={{ verticalAlign: 'middle' }}>
                          {signatureDetails}
                        </td>
                        <td width="30">
                          <div style={{ width: '30px' }}></div>
                        </td>
                        <td color={themeColor} direction="vertical" height="1" style={{ width: '1px', borderBottom: 'none', borderLeft: '1px solid ' + themeColor }}></td>
                        <td width="30">
                          <div style={{ width: '30px' }}></div>
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          {signatureContacts}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', width: '100%', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                    <tbody>
                      <tr>
                        <td height="30"></td>
                      </tr>
                      <tr>
                        <td color={themeColor} direction="horizontal" height="1" style={{ display: 'block', width: '100%', borderLeft: 'none', borderBottom: '1px solid ' + themeColor }}></td>
                      </tr>
                      <tr>
                        <td height="30"></td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', width: '100%', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                    <tbody>
                      <tr>
                        {signatureCompanyLogo}
                        <td style={{ verticalAlign: 'top', textAlign: 'right' }}>
                          {signatureSocialLinks}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              {CTAStyleValue === 'none' &&
                <>
                  {createdWith === 'checked' &&
                    <>
                      <tr>
                        <td>
                          <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', width: '100%', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                            <tbody>
                              <tr>
                                <td height="15"></td>
                              </tr>
                              <tr>
                                <td>
                                  {signatureLink}                      
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </>
                  }
                </>
              }
              {CTAStyleValue === 'button' &&
                <>
                  <tr>
                    <td>
                      <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', width: '100%', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                        <tbody>
                          <tr>
                            <td height="15"></td>
                          </tr>
                          <tr>
                            {createdWith === 'checked' &&
                              <>
                                <td>
                                  {signatureLink}                      
                                </td>
                                <td width="15"></td>
                              </>
                            }
                            {signatureCTAButton}
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </>
              }
              {CTAStyleValue === 'image' &&
                <>
                  <tr>
                    <td height="30"></td>
                  </tr>
                  <tr>
                    {signatureCTAImage}
                  </tr>
                  {createdWith === 'checked' &&
                    <>
                      <tr>
                        <td height="30"></td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'center' }}>
                          {signatureLink}                      
                        </td>
                      </tr>
                    </>
                  }
                </>
              }
            </tbody>
          </table>
        }
        {template === '6' &&
          <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
            <tbody>
              <tr>
                {signatureProfilePicture}
              </tr>
              <tr>
                <td height="10"></td>
              </tr>
              <tr style={{ textAlign: 'center' }}>
                <td>
                  {signatureDetails}
                </td>
              </tr>
              <tr>
                <td>
                  <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', width: '100%', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                    <tbody>
                      <tr>
                        <td height="30"></td>
                      </tr>
                      <tr>
                        <td color={themeColor} direction="horizontal" height="1" style={{ display: 'block', width: '100%', borderLeft: 'none', borderBottom: '1px solid ' + themeColor }}></td>
                      </tr>
                      <tr>
                        <td height="30"></td>
                      </tr>
                    </tbody>
                  </table>
                  <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', width: '100%', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                    <tbody>
                      <tr style={{ verticalAlign: 'middle' }}>
                        <td>
                          {signatureContacts}
                        </td>
                        <td width="15">
                          <div></div>
                        </td>
                        <td style={{ textAlign: 'right' }}>
                          <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', width: '100%', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                            <tbody>
                              <tr>
                                {signatureCompanyLogo}
                              </tr>
                              <tr>
                                <td height="10"></td>
                              </tr>
                              <tr>
                                <td>
                                  {signatureSocialLinks}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', width: '100%', fontSize: fontSizeValue, fontFamily: fontFamilyValue }}>
                    <tbody>
                      <tr>
                        <td height="30"></td>
                      </tr>
                      <tr>
                        <td color={themeColor} direction="horizontal" height="1" style={{ display: 'block', width: '100%', borderLeft: 'none', borderBottom: '1px solid ' + themeColor }}></td>
                      </tr>
                      <tr>
                        <td height="30"></td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              {CTAStyleValue === 'none' &&
                <>
                  {createdWith === 'checked' &&
                    <>
                      <tr>
                        <td height="30"></td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'center' }}>
                          {signatureLink}                      
                        </td>
                      </tr>
                    </>
                  }
                </>
              }
              {CTAStyleValue === 'button' &&
                <>
                  <tr>
                    {signatureCTAButton}
                  </tr>
                  {createdWith === 'checked' &&
                    <>
                      <tr>
                        <td height="30"></td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'center' }}>
                          {signatureLink}                      
                        </td>
                      </tr>
                    </>
                  }
                </>
              }
              {CTAStyleValue === 'image' &&
                <>
                  <tr>
                    <td height="30"></td>
                  </tr>
                  <tr>
                    {signatureCTAImage}
                  </tr>
                  {createdWith === 'checked' &&
                    <>
                      <tr>
                        <td height="30"></td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'center' }}>
                          {signatureLink}                      
                        </td>
                      </tr>
                    </>
                  }
                </>
              }
            </tbody>
          </table>
        }
       </>
    );

    const signatureHTML = ReactDOMServer.renderToStaticMarkup(
      signature
    );

    setSignatureHTML(signatureHTML);

  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    updateSignature();
  });

  // Submit
  const {
    register,
    handleSubmit,
    trigger,
    control,
    setValue,
    reset,
    formState: { errors, isSubmitting }
  } = useForm();
  const onSubmit = (data) => console.log(data);

  const showForm = (
    <form method="post" onSubmit={handleSubmit(onSubmit)} className="card">
      <div className="card-header border-bottom">
        <ul className="nav nav-segment nav-fill" role="presentation">
          <li className="nav-item">
            <a className="nav-link active" id="nav-templates-tab" href="#nav-templates" data-bs-toggle="pill" data-bs-target="#nav-templates" role="tab" aria-controls="nav-templates" aria-selected="true"><i className="bi bi-layout-text-window-reverse me-1"></i> {t('form.templates')}</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="nav-style-tab" href="#nav-style" data-bs-toggle="pill" data-bs-target="#nav-style" role="tab" aria-controls="nav-style" aria-selected="false"><i className="bi bi-brush me-1"></i> {t('form.style')}</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="nav-details-tab" href="#nav-details" data-bs-toggle="pill" data-bs-target="#nav-details" role="tab" aria-controls="nav-details" aria-selected="false"><i className="bi bi-card-text me-1"></i> {t('form.details')}</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="nav-contacts-tab" href="#nav-contacts" data-bs-toggle="pill" data-bs-target="#nav-contacts" role="tab" aria-controls="nav-contacts" aria-selected="false"><i className="bi bi-person-lines-fill me-1"></i> {t('form.contacts')}</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="nav-social-networks-tab" href="#nav-social-networks" data-bs-toggle="pill" data-bs-target="#nav-social-networks" role="tab" aria-controls="nav-social-networks" aria-selected="false"><i className="bi bi-person-badge me-1"></i> {t('form.socialNetworks')}</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="nav-cta-tab" href="#nav-cta" data-bs-toggle="pill" data-bs-target="#nav-cta" role="tab" aria-controls="nav-cta" aria-selected="false"><i className="bi bi-cursor me-1"></i> {t('form.cta')}</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="nav-images-tab" href="#nav-images" data-bs-toggle="pill" data-bs-target="#nav-images" role="tab" aria-controls="nav-images" aria-selected="false"><i className="bi bi-images me-1"></i> {t('form.images')}</a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div className="tab-pane fade show active" id="nav-templates" role="tabpanel" aria-labelledby="nav-templates-tab">
            <div className="border-bottom mb-3">
              <h3 className="mb-3">{t('form.templates')}</h3>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-12 col-xl-6 mb-3">
                <label className="form-check-label w-100" htmlFor="template-1">
                  <div className="form-check form-check-card text-center">
                    <input
                      type="radio"
                      name="template"
                      id="template-1"
                      className="form-check-input"
                      value="1"
                      defaultChecked={template === "1"}
                      onClick={handleClickTemplate}
                      {...register("template", { required: true })}
                      disabled={isSubmitting}
                    />
                    <StaticImage className="w-75 mb-3" objectFit="fill" quality="30" src="../../images/resources/email-signature-generator/templates/1.png" alt={t('form.templates')} />
                    <span className="d-block">{t('form.template.label', { number: 1 })}</span>
                  </div>
                </label>
              </div>
              <div className="col-12 col-md-6 col-lg-12 col-xl-6 mb-3">
                <label className="form-check-label w-100" htmlFor="template-2">
                  <div className="form-check form-check-card text-center">
                    <input
                      type="radio"
                      name="template"
                      id="template-2"
                      className="form-check-input"
                      value="2"
                      defaultChecked={template === "2"}
                      onClick={handleClickTemplate}
                      {...register("template", { required: true })}
                      disabled={isSubmitting}
                    />
                    <StaticImage className="w-75 mb-3" objectFit="fill" quality="30" src="../../images/resources/email-signature-generator/templates/2.png" alt={t('form.templates')} />
                    <span className="d-block">{t('form.template.label', { number: 2 })}</span>
                  </div>
                </label>
              </div>
              <div className="col-12 col-md-6 col-lg-12 col-xl-6 mb-3">
                <label className="form-check-label w-100" htmlFor="template-3">
                  <div className="form-check form-check-card text-center">
                    <input
                      type="radio"
                      name="template"
                      id="template-3"
                      className="form-check-input"
                      value="3"
                      defaultChecked={template === "3"}
                      onClick={handleClickTemplate}
                      {...register("template", { required: true })}
                      disabled={isSubmitting}
                    />
                    <StaticImage className="w-75 mb-3" objectFit="fill" quality="30" src="../../images/resources/email-signature-generator/templates/3.png" alt={t('form.templates')} />
                    <span className="d-block">{t('form.template.label', { number: 3 })}</span>
                  </div>
                </label>
              </div>
              <div className="col-12 col-md-6 col-lg-12 col-xl-6 mb-3">
                <label className="form-check-label w-100" htmlFor="template-4">
                  <div className="form-check form-check-card text-center">
                    <input
                      type="radio"
                      name="template"
                      id="template-4"
                      className="form-check-input"
                      value="4"
                      defaultChecked={template === "4"}
                      onClick={handleClickTemplate}
                      {...register("template", { required: true })}
                      disabled={isSubmitting}
                    />
                    <StaticImage className="w-75 mb-3" objectFit="fill" quality="30" src="../../images/resources/email-signature-generator/templates/4.png" alt={t('form.templates')} />
                    <span className="d-block">{t('form.template.label', { number: 4 })}</span>
                  </div>
                </label>
              </div>
              <div className="col-12 col-md-6 col-lg-12 col-xl-6 mb-3">
                <label className="form-check-label w-100" htmlFor="template-5">
                  <div className="form-check form-check-card text-center">
                    <input
                      type="radio"
                      name="template"
                      id="template-5"
                      className="form-check-input"
                      value="5"
                      defaultChecked={template === "5"}
                      onClick={handleClickTemplate}
                      {...register("template", { required: true })}
                      disabled={isSubmitting}
                    />
                    <StaticImage className="w-75 mb-3" objectFit="fill" quality="30" src="../../images/resources/email-signature-generator/templates/5.png" alt={t('form.templates')} />
                    <span className="d-block">{t('form.template.label', { number: 5 })}</span>
                  </div>
                </label>
              </div>
              <div className="col-12 col-md-6 col-lg-12 col-xl-6 mb-3">
                <label className="form-check-label w-100" htmlFor="template-6">
                  <div className="form-check form-check-card text-center">
                    <input
                      type="radio"
                      name="template"
                      id="template-6"
                      className="form-check-input"
                      value="6"
                      defaultChecked={template === "6"}
                      onClick={handleClickTemplate}
                      {...register("template", { required: true })}
                      disabled={isSubmitting}
                    />
                    <StaticImage className="w-75 mb-3" objectFit="fill" quality="30" src="../../images/resources/email-signature-generator/templates/6.png" alt={t('form.templates')} />
                    <span className="d-block">{t('form.template.label', { number: 6 })}</span>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="nav-style" role="tabpanel" aria-labelledby="nav-style-tab">
            <div className="border-bottom mb-3">
              <h3 className="mb-3">{t('form.style')}</h3>
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="theme-color">{t('form.themeColor.label')}</label>
              <div className="input-group input-group-sm">
                <Controller
                  name="theme_color"
                  id="theme-color"
                  maxLength="20"
                  rules={{ required: false }}
                  disabled={isSubmitting}
                  control={control}
                  render={({ field }) => (
                    <>
                      <span className="input-group-text">#</span>
                      <HexColorInput 
                        color={themeColor} 
                        onChange={handleChangeThemeColor}
                        className="form-control form-control-sm"
                      />
                      <span className="input-group-text">
                        <PopoverPicker 
                          color={themeColor} 
                          onChange={handleChangeThemeColor}
                        />
                      </span>
                    </>
                  )}
                />
                </div>
              {errors.theme_color && errors.theme_color.type === "required" && <div className="text-danger">{t('form.themeColor.required')}</div>}
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="text-color">{t('form.textColor.label')}</label>
              <div className="input-group input-group-sm">
                <Controller
                  name="text_color"
                  id="text-color"
                  maxLength="20"
                  rules={{ required: false }}
                  disabled={isSubmitting}
                  control={control}
                  render={({ field }) => (
                    <>
                      <span className="input-group-text">#</span>
                      <HexColorInput 
                        color={textColor} 
                        onChange={handleChangeTextColor}
                        className="form-control form-control-sm"
                      />
                      <span className="input-group-text">
                        <PopoverPicker 
                          color={textColor} 
                          onChange={handleChangeTextColor}
                        />
                      </span>
                    </>
                  )}
                />
                </div>
              {errors.text_color && errors.text_color.type === "required" && <div className="text-danger">{t('form.textColor.required')}</div>}
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="font-family">{t('form.fontFamily.label')}</label>
              <Controller
                name="font_family"
                id="font-family"
                rules={{ required: false }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.fontFamily.placeholder')}
                    cacheOptions
                    defaultOptions
                    value={fontFamily}
                    options={optionsFontFamily}
                    onChange={handleChangeFontFamily}
                    styles={selectCustomStyles}
                    theme={selectTheme}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  />
                )}
              />
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="font-size">{t('form.fontSize.label')}</label>
              <Controller
                name="font_size"
                id="font-size"
                rules={{ required: false }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.fontSize.placeholder')}
                    cacheOptions
                    defaultOptions
                    value={fontSize}
                    options={optionsFontSize}
                    onChange={handleChangeFontSize}
                    styles={selectCustomStyles}
                    theme={selectTheme}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  />
                )}
              />
            </div>
            <div className="form-check form-switch mb-4 pt-4 border-top">
              <input
                type="checkbox"
                name="created_with"
                id="created-with"
                className="form-check-input"
                value="checked"
                defaultChecked={createdWith}
                onClick={handleClickCreatedWith}
                {...register("created_with", { required: true })}
                disabled={isSubmitting}
              />
              <label className="form-check-label" htmlFor="created-with">{t('form.createdWith.label', { title: data.site.siteMetadata.title })}</label>                    
            </div>
          </div>
          <div className="tab-pane fade" id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab">
            <div className="border-bottom mb-3">
              <h3 className="mb-3">{t('form.details')}</h3>
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="first-name">{t('form.firstName.label')}<span className="required" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('common.mandatory')}>*</span></label>
              <Controller
                name="first_name"
                id="first-name"
                rules={{ 
                  required: {
                    value: true,
                    message: t('form.firstName.required'),
                  },                  
                }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.firstName.placeholder')}
                    maxLength="100"
                    value={firstName}
                    onChange={handleChangeFirstName}
                  />
                )}
              />
              {errors.first_name && errors.first_name.type === "required" && <div className="text-danger">{t('form.firstName.required')}</div>}
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="last-name">{t('form.lastName.label')}<span className="required" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('common.mandatory')}>*</span></label>
              <Controller
                name="last_name"
                id="last-name"
                rules={{ 
                  required: {
                    value: true,
                    message: t('form.lastName.required'),
                  },                  
                }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.lastName.placeholder')}
                    maxLength="100"
                    value={lastName}
                    onChange={handleChangeLastName}
                  />
                )}
              />
              {errors.last_name && errors.last_name.type === "required" && <div className="text-danger">{t('form.lastName.required')}</div>}
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="job-title">{t('form.jobTitle.label')}</label>
              <Controller
                name="job_title"
                id="job-titlee"
                rules={{ required: false }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.jobTitle.placeholder')}
                    maxLength="100"
                    value={jobTitle}
                    onChange={handleChangeJobTitle}
                  />
                )}
              />
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="department">{t('form.department.label')}</label>
              <Controller
                name="department"
                id="department"
                rules={{ required: false }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.department.placeholder')}
                    maxLength="100"
                    value={department}
                    onChange={handleChangeDepartment}
                  />
                )}
              />
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="enterprise">{t('form.enterprise.label')}</label>
              <Controller
                name="enterprise"
                id="enterprise"
                rules={{ required: false }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.enterprise.placeholder')}
                    maxLength="100"
                    value={enterprise}
                    onChange={handleChangeEnterprise}
                  />
                )}
              />
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="custom-field">{t('form.customField.label')}</label>
              <Controller
                name="custom_field"
                id="custom-field"
                rules={{ required: false }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.customField.placeholder')}
                    maxLength="100"
                    value={customField}
                    onChange={handleChangeCustomField}
                  />
                )}
              />
            </div>
          </div>
          <div className="tab-pane fade" id="nav-contacts" role="tabpanel" aria-labelledby="nav-contacts-tab">
            <div className="border-bottom mb-3">
              <h3 className="mb-3">{t('form.contacts')}</h3>
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="office-telephone-number">{t('form.officeTelephoneNumber.label')}</label>
              <Controller
                name="office_telephone_number"
                id="office-telephone-number"
                maxLength="20"
                rules={{ required: false }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <IntlTelInput
                    inputClassName="form-control form-control-sm"
                    nationalMode={false}
                    autoPlaceholder={true}
                    autoHideDialCode={true}
                    format
                    formatOnInit={false}
                    preferredCountries={["fr", "gb", "ru"]}
                    value={officeTelephoneNumber} 
                    onPhoneNumberChange={handleChangeOfficeTelephoneNumber}
                    onPhoneNumberBlur={handleBlurOfficeTelephoneNumber}
                    placeholder={t('form.officeTelephoneNumber.placeholder')}
                  />
                )}
              />
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="mobile-telephone-number">{t('form.mobileTelephoneNumber.label')}</label>
              <Controller
                name="mobile_telephone_number"
                id="mobile-telephone-number"
                maxLength="20"
                rules={{ required: false }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <IntlTelInput
                    inputClassName="form-control form-control-sm"
                    nationalMode={false}
                    autoPlaceholder={true}
                    autoHideDialCode={true}
                    format
                    formatOnInit={false}
                    preferredCountries={["fr", "gb", "ru"]}
                    value={mobileTelephoneNumber} 
                    onPhoneNumberChange={handleChangeMobileTelephoneNumber}
                    onPhoneNumberBlur={handleBlurMobileTelephoneNumber}
                    placeholder={t('form.mobileTelephoneNumber.placeholder')}
                  />
                )}
              />
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="email">{t('form.email.label')}</label>
              <Controller
                name="email"
                id="email"
                rules={{ 
                  required: false,
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: t('form.email.valid')
                  },                 
                }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.email.placeholder')}
                    maxLength="255"
                    value={email}
                    onChange={handleChangeEmail}
                  />
                )}
              />
              {errors.email && errors.email.type === "pattern" && <div className="text-danger">{t('form.email.valid')}</div>}
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="website-url">{t('form.websiteURL.label')}</label>
              <Controller
                name="website_url"
                id="website-url"
                rules={{ 
                  required: false,
                  pattern: {
                    value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                    message: t('form.websiteURL.valid')
                  },                 
                }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.websiteURL.placeholder')}
                    maxLength="255"
                    value={websiteURL}
                    onChange={handleChangeWebsiteURL}
                  />
                )}
              />
              {errors.website_url && errors.website_url.type === "pattern" && <div className="text-danger">{t('form.websiteURL.valid')}</div>}
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="address-street-number">{t('form.address.label')}</label>
              <Controller
                name="address_street_number"
                id="address-street-number"
                rules={{ required: false }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.addressStreetNumber.placeholder')}
                    maxLength="100"
                    value={addressStreetNumber}
                    onChange={handleChangeAddressStreetNumber}
                  />
                )}
              />
            </div>
            <div className="form-group mb-3">
              <Controller
                name="address_street"
                id="address-street"
                rules={{ required: false }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.addressStreet.placeholder')}
                    maxLength="200"
                    value={addressStreet}
                    onChange={handleChangeAddressStreet}
                  />
                )}
              />
            </div>
            <div className="form-group mb-3">
              <Controller
                name="address_postal_code"
                id="address-postal-code"
                rules={{ required: false }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.addressPostalCode.placeholder')}
                    maxLength="20"
                    value={addressPostalCode}
                    onChange={handleChangeAddressPostalCode}
                  />
                )}
              />
            </div>
            <div className="form-group mb-3">
              <Controller
                name="address_city"
                id="address-city"
                rules={{ required: false }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.addressCity.placeholder')}
                    maxLength="200"
                    value={addressCity}
                    onChange={handleChangeAddressCity}
                  />
                )}
              />
            </div>
            <div className="form-group mb-3">
              <Controller
                name="address_country"
                id="address-country"
                rules={{ required: false }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.addressCountry.placeholder')}
                    maxLength="50"
                    value={addressCountry}
                    onChange={handleChangeAddressCountry}
                  />
                )}
              />
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="address-url">{t('form.addressDirectionURL.label')}</label>
              <Controller
                name="address_direction_url"
                id="address-url"
                rules={{ 
                  required: false,
                  pattern: {
                    value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                    message: t('form.addressDirectionURL.valid')
                  },                 
                }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.addressDirectionURL.placeholder')}
                    maxLength="2000"
                    value={addressDirectionURL}
                    onChange={handleChangeAddressDirectionURL}
                  />
                )}
              />
              {errors.address_direction_url && errors.address_direction_url.type === "pattern" && <div className="text-danger">{t('form.addressDirectionURL.valid')}</div>}
            </div>
            <div className="pt-3 mb-3 border-top">
              <div className="form-group mb-3">
                <label className="form-label" htmlFor="cta-style">{t('form.contactIconsSize.label')}</label>
                <Controller
                  name="contact_icons_size"
                  id="social-networks-style"
                  rules={{ required: false }}
                  disabled={isSubmitting}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="form-control form-control-sm"
                      placeholder={t('form.contactIconsSize.placeholder')}
                      cacheOptions
                      defaultOptions
                      value={contactIconsSize}
                      options={optionsContactIconsSize}
                      onChange={handleChangeContactIconsSize}
                      styles={selectCustomStyles}
                      theme={selectTheme}
                      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    />
                  )}
                />
              </div>
              <div className="form-group mb-3">
                <label className="form-label" htmlFor="theme-color">{t('form.contactIconsCustomColor.label')}</label>
                <div className="input-group input-group-sm">
                  <Controller
                    name="contact_icons_custom_color"
                    id="social-network-icons-custom-color"
                    maxLength="20"
                    rules={{ required: false }}
                    disabled={isSubmitting}
                    control={control}
                    render={({ field }) => (
                      <>
                        <span className="input-group-text">#</span>
                        <HexColorInput 
                          color={contactIconsCustomColor} 
                          onChange={handleChangeContactIconsCustomColor}
                          className="form-control form-control-sm"
                        />
                        <span className="input-group-text">
                          <PopoverPicker 
                            color={contactIconsCustomColor} 
                            onChange={handleChangeContactIconsCustomColor}
                          />
                        </span>
                      </>
                    )}
                  />
                  </div>
                {errors.theme_color && errors.theme_color.type === "required" && <div className="text-danger">{t('form.themeColor.required')}</div>}
              </div>
              <div className="form-group mb-3">
                <label className="form-label" htmlFor="cta-style">{t('form.contactIconsStyle.label')}</label>
                <Controller
                  name="contact_icons_style"
                  id="social-networks-style"
                  rules={{ required: false }}
                  disabled={isSubmitting}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="form-control form-control-sm"
                      placeholder={t('form.contactIconsStyle.placeholder')}
                      cacheOptions
                      defaultOptions
                      value={contactIconsStyle}
                      options={optionsContactIconsStyle}
                      onChange={handleChangeContactIconsStyle}
                      styles={selectCustomStyles}
                      theme={selectTheme}
                      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    />
                  )}
                />
              </div>
              {(contactIconsStyleValue === 'border' || contactIconsStyleValue === 'background') &&
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="cta-style">{t('form.contactIconsShape.label')}</label>
                  <Controller
                    name="contact_icons_shape"
                    id="social-networks-style"
                    rules={{ required: false }}
                    disabled={isSubmitting}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className="form-control form-control-sm"
                        placeholder={t('form.contactIconsShape.placeholder')}
                        cacheOptions
                        defaultOptions
                        value={contactIconsShape}
                        options={optionsContactIconsShape}
                        onChange={handleChangeContactIconsShape}
                        styles={selectCustomStyles}
                        theme={selectTheme}
                        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                      />
                    )}
                  />
                </div>
              }
            </div>
          </div>
          <div className="tab-pane fade" id="nav-social-networks" role="tabpanel" aria-labelledby="nav-social-networks-tab">
            <div className="border-bottom mb-3">
              <h3 className="mb-3">{t('form.socialNetworks')}</h3>
            </div>
            {socialNetworks.length > 0 && (
              <>
                {socialNetworks.map((socialNetwork, index) => (
                  <div key={index}>
                    <div className="border-bottom mb-3">
                      <div className="row align-items-center">
                        <div className="col-sm mb-2 mb-sm-0">
                          <h4>{t('form.socialNetwork.label', { number: index + 1 })}</h4>
                        </div>
                        <div className="col-sm-auto">
                          <button
                            type="button"
                            className="btn btn-outline-danger btn-xs flex-end"
                            onClick={() => handleRemoveSocialNetwork(index)}
                            data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('form.socialNetwork.remove.tooltip')}
                          >
                            <i className="bi-trash me-1"></i> {t('form.socialNetwork.remove.button')}
                          </button>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <Controller
                          name={"social_network_type_" + index}
                          id="social-network-type"
                          rules={{ required: false }}
                          disabled={isSubmitting}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              className="form-control form-control-sm mt-3"
                              placeholder={t('form.socialNetworkType.placeholder')}
                              cacheOptions
                              defaultOptions
                              value={socialNetwork.type}
                              options={optionsSocialNetworkType}
                              onChange={(event) =>
                                handleChangeSocialNetworkType(index, event)
                              }
                              styles={selectCustomStyles}
                              theme={selectTheme}
                              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                            />
                          )}
                        />
                      </div>
                      {socialNetworkTypes.length > 0 && (
                        <>
                          {socialNetworkTypes.map((socialNetworkType, indexSocialNetworkType) => (
                            <div key={indexSocialNetworkType}>
                              {socialNetwork.type.value === socialNetworkType.value &&
                                  <div className="form-group mb-3">
                                    <div className="input-group input-group-sm mb-3">
                                      {socialNetworkType.url.value && socialNetworkType.url.position === 'prepend' &&
                                        <span className="input-group-text">{socialNetworkType.url.value}</span>
                                      }
                                      <Controller
                                        name={"social_network_text_" + index}
                                        id="social-network-text"
                                        rules={{ required: false }}
                                        disabled={isSubmitting}
                                        control={control}
                                        render={({ field }) => (
                                          <input
                                            {...field}
                                            className="form-control form-control-sm"
                                            placeholder={t('form.socialNetworkText.'+ socialNetworkType.value + '.placeholder')}
                                            maxLength="200"
                                            value={socialNetwork.text}
                                            onChange={(event) =>
                                              handleChangeSocialNetworkText(index, event)
                                            }
                                          />
                                        )}
                                      />
                                      {socialNetworkType.url.value && socialNetworkType.url.position === 'append' &&
                                        <span className="input-group-text">{socialNetworkType.url.value}</span>
                                      }
                                    </div>
                                  </div>
                                }
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
            <div className="pb-3 mb-3 border-bottom">
              <button 
                type="button" 
                className="btn btn-primary btn-sm" 
                onClick={() => handleAddSocialNetwork()}
                data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('form.socialNetwork.add.tooltip')}
              >
                <i className="bi-plus-circle"></i> {t('form.socialNetwork.add.button')}
              </button>
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="cta-style">{t('form.socialNetworkIconsSize.label')}</label>
              <Controller
                name="social_network_icons_size"
                id="social-networks-style"
                rules={{ required: false }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.socialNetworkIconsSize.placeholder')}
                    cacheOptions
                    defaultOptions
                    value={socialNetworkIconsSize}
                    options={optionsSocialNetworkIconsSize}
                    onChange={handleChangeSocialNetworkIconsSize}
                    styles={selectCustomStyles}
                    theme={selectTheme}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  />
                )}
              />
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="cta-style">{t('form.socialNetworkIconsColor.label')}</label>
              <Controller
                name="social_network_icons_color"
                id="social-networks-style"
                rules={{ required: false }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.socialNetworkIconsColor.placeholder')}
                    cacheOptions
                    defaultOptions
                    value={socialNetworkIconsColor}
                    options={optionsSocialNetworkIconsColor}
                    onChange={handleChangeSocialNetworkIconsColor}
                    styles={selectCustomStyles}
                    theme={selectTheme}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  />
                )}
              />
            </div>
            {socialNetworkIconsColorValue === 'custom' &&
              <div className="form-group mb-3">
                <label className="form-label" htmlFor="theme-color">{t('form.socialNetworkIconsCustomColor.label')}</label>
                <div className="input-group input-group-sm">
                  <Controller
                    name="social_network_icons_custom_color"
                    id="social-network-icons-custom-color"
                    maxLength="20"
                    rules={{ required: false }}
                    disabled={isSubmitting}
                    control={control}
                    render={({ field }) => (
                      <>
                        <span className="input-group-text">#</span>
                        <HexColorInput 
                          color={socialNetworkIconsCustomColor} 
                          onChange={handleChangeSocialNetworkIconsCustomColor}
                          className="form-control form-control-sm"
                        />
                        <span className="input-group-text">
                          <PopoverPicker 
                            color={socialNetworkIconsCustomColor} 
                            onChange={handleChangeSocialNetworkIconsCustomColor}
                          />
                        </span>
                      </>
                    )}
                  />
                  </div>
                {errors.theme_color && errors.theme_color.type === "required" && <div className="text-danger">{t('form.themeColor.required')}</div>}
              </div>
            }
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="cta-style">{t('form.socialNetworkIconsStyle.label')}</label>
              <Controller
                name="social_network_icons_style"
                id="social-networks-style"
                rules={{ required: false }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.socialNetworkIconsStyle.placeholder')}
                    cacheOptions
                    defaultOptions
                    value={socialNetworkIconsStyle}
                    options={optionsSocialNetworkIconsStyle}
                    onChange={handleChangeSocialNetworkIconsStyle}
                    styles={selectCustomStyles}
                    theme={selectTheme}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  />
                )}
              />
            </div>
            {(socialNetworkIconsStyleValue === 'border' || socialNetworkIconsStyleValue === 'background') &&
              <div className="form-group mb-3">
                <label className="form-label" htmlFor="cta-style">{t('form.socialNetworkIconsShape.label')}</label>
                <Controller
                  name="social_network_icons_shape"
                  id="social-networks-style"
                  rules={{ required: false }}
                  disabled={isSubmitting}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="form-control form-control-sm"
                      placeholder={t('form.socialNetworkIconsShape.placeholder')}
                      cacheOptions
                      defaultOptions
                      value={socialNetworkIconsShape}
                      options={optionsSocialNetworkIconsShape}
                      onChange={handleChangeSocialNetworkIconsShape}
                      styles={selectCustomStyles}
                      theme={selectTheme}
                      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    />
                  )}
                />
              </div>
            }
          </div>
          <div className="tab-pane fade" id="nav-cta" role="tabpanel" aria-labelledby="nav-cta-tab">
            <div className="border-bottom mb-3">
              <h3 className="mb-3">{t('form.cta')}</h3>
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="cta-style">{t('form.ctaStyle.label')}</label>
              <Controller
                name="cta_style"
                id="cta-style"
                rules={{ required: false }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.ctaStyle.placeholder')}
                    cacheOptions
                    defaultOptions
                    value={CTAStyle}
                    options={optionsCTAStyle}
                    onChange={handleChangeCTAStyle}
                    styles={selectCustomStyles}
                    theme={selectTheme}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  />
                )}
              />
            </div>
            {CTAStyleValue === 'button' &&
              <>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="cta-button-text">{t('form.ctaButtonText.label')}</label>
                  <Controller
                    name="cta_button_text"
                    id="cta-button-text"
                    rules={{ 
                      required: {
                        value: true,
                        message: t('form.ctaButtonText.required'),
                      },                
                    }}
                    disabled={isSubmitting}
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        className="form-control form-control-sm"
                        placeholder={t('form.ctaButtonText.placeholder')}
                        maxLength="50"
                        value={CTAButtonText}
                        onChange={handleChangeCTAButtonText}
                      />
                    )}
                  />                  
                  {errors.cta_button_text && errors.cta_button_text.type === "required" && <div className="text-danger">{t('form.ctaButtonText.required')}</div>}
                </div>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="cta-button-url">{t('form.ctaButtonURL.label')}</label>
                  <Controller
                    name="cta_button_url"
                    id="cta-button-url"
                    rules={{ 
                      required: {
                        value: true,
                        message: t('form.ctaButtonURL.required'),
                      },                      
                      pattern: {
                        value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                        message: t('form.ctaButtonURL.valid')
                      },                 
                    }}
                    disabled={isSubmitting}
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        className="form-control form-control-sm"
                        placeholder={t('form.ctaButtonURL.placeholder')}
                        maxLength="2000"
                        value={CTAButtonURL}
                        onChange={handleChangeCTAButtonURL}
                      />
                    )}
                  />
                  {errors.cta_button_url && errors.cta_button_url.type === "required" && <div className="text-danger">{t('form.ctaButtonURL.required')}</div>}
                  {errors.cta_button_url && errors.cta_button_url.type === "pattern" && <div className="text-danger">{t('form.ctaButtonURL.valid')}</div>}
                </div>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="cta-button-background-color">{t('form.ctaButtonBackgroundColor.label')}</label>
                  <div className="input-group input-group-sm">
                    <Controller
                      name="cta_button_background_color"
                      id="cta-button-background-color"
                      maxLength="20"
                      rules={{ required: false }}
                      disabled={isSubmitting}
                      control={control}
                      render={({ field }) => (
                        <>
                          <span className="input-group-text">#</span>
                          <HexColorInput 
                            color={CTAButtonBackgroundColor} 
                            onChange={handleChangeCTAButtonBackgroundColor}
                            className="form-control form-control-sm"
                          />
                          <span className="input-group-text">
                            <PopoverPicker 
                              color={CTAButtonBackgroundColor} 
                              onChange={handleChangeCTAButtonBackgroundColor}
                            />
                          </span>
                        </>
                      )}
                    />
                    </div>
                  {errors.cta_button_background_color && errors.cta_button_background_color.type === "required" && <div className="text-danger">{t('form.ctaButtonBackgroundColor.required')}</div>}
                </div>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="cta-button-text-color">{t('form.ctaButtonTextColor.label')}</label>
                  <div className="input-group input-group-sm">
                    <Controller
                      name="cta_button_text_color"
                      id="cta-button-text-color"
                      maxLength="20"
                      rules={{ required: false }}
                      disabled={isSubmitting}
                      control={control}
                      render={({ field }) => (
                        <>
                          <span className="input-group-text">#</span>
                          <HexColorInput 
                            color={CTAButtonTextColor} 
                            onChange={handleChangeCTAButtonTextColor}
                            className="form-control form-control-sm"
                          />
                          <span className="input-group-text">
                            <PopoverPicker 
                              color={CTAButtonTextColor} 
                              onChange={handleChangeCTAButtonTextColor}
                            />
                          </span>
                        </>
                      )}
                    />
                    </div>
                  {errors.cta_button_text_color && errors.cta_button_text_color.type === "required" && <div className="text-danger">{t('form.ctaButtonTextColor.required')}</div>}
                </div>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="cta-button-shape">{t('form.ctaButtonShape.label')}</label>
                  <Controller
                    name="cta_button_shape"
                    id="cta-button-shape"
                    rules={{ required: false }}
                    disabled={isSubmitting}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className="form-control form-control-sm"
                        placeholder={t('form.ctaButtonShape.placeholder')}
                        cacheOptions
                        defaultOptions
                        value={CTAButtonShape}
                        options={optionsCTAButtonShape}
                        onChange={handleChangeCTAButtonShape}
                        styles={selectCustomStyles}
                        theme={selectTheme}
                        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                      />
                    )}
                  />
                </div>
              </>
            }
            {CTAStyleValue === 'image' &&
              <>
                <div className="form-group mb-3">
                  <div>
                    <label className="form-label" htmlFor="company-picture">{t('form.ctaImage.label')}</label>
                    <span className="float-end"><i className="bi-info-circle" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('form.ctaImage.tooltip')}></i></span>
                  </div>
                  <Controller
                    name="cta_image"
                    id="cta-image"
                    rules={{ 
                      required: {
                        value: true,
                        message: t('form.ctaImage.required'),
                      },
                      pattern: {
                        value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                        message: t('form.ctaImage.valid'),
                      },              
                    }}
                    disabled={isSubmitting}
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        className="form-control form-control-sm"
                        placeholder={t('form.ctaImage.placeholder')}
                        maxLength="2000"
                        value={CTAImage}
                        onChange={handleChangeCTAImage}
                      />
                    )}
                  />
                  {errors.cta_image && errors.cta_image.type === "required" && <div className="text-danger">{t('form.ctaImage.required')}</div>}
                  {errors.cta_image && errors.cta_image.type === "pattern" && <div className="text-danger">{t('form.ctaImage.valid')}</div>}
                </div>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="cta-image-url">{t('form.ctaImageURL.label')}</label>
                  <Controller
                    name="cta_image_url"
                    id="cta-image-url"
                    rules={{ 
                      required: {
                        value: true,
                        message: t('form.ctaImageURL.required'),
                      },
                      pattern: {
                        value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                        message: t('form.ctaImageURL.valid'),
                      },              
                    }}
                    disabled={isSubmitting}
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        className="form-control form-control-sm"
                        placeholder={t('form.ctaImageURL.placeholder')}
                        maxLength="2000"
                        value={CTAImageURL}
                        onChange={handleChangeCTAImageURL}
                      />
                    )}
                  />
                  {errors.cta_image_url && errors.cta_image_url.type === "required" && <div className="text-danger">{t('form.ctaImageURL.required')}</div>}
                  {errors.cta_image_url && errors.cta_image_url.type === "pattern" && <div className="text-danger">{t('form.ctaImageURL.valid')}</div>}
                </div>
              </>
            }
          </div>
          <div className="tab-pane fade" id="nav-images" role="tabpanel" aria-labelledby="nav-images-tab">
            <div className="border-bottom mb-3">
              <h3 className="mb-3">{t('form.images')}</h3>
            </div>
            <div className="form-group mb-3">
              <div>
                <label className="form-label" htmlFor="profile-picture">{t('form.profilePicture.label')}</label>
                <span className="float-end"><i className="bi-info-circle" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('form.profilePicture.tooltip')}></i></span>
              </div>
              <Controller
                name="profile_picture"
                id="profile-picture"
                rules={{ 
                  required: false,
                  pattern: {
                    value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                    message: t('form.profilePicture.valid'),
                  },              
                }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.profilePicture.placeholder')}
                    maxLength="2000"
                    value={profilePicture}
                    onChange={handleChangeProfilePicture}
                  />
                )}
              />
              {errors.profile_picture && errors.profile_picture.type === "pattern" && <div className="text-danger">{t('form.profilePicture.valid')}</div>}
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="profile-picture-shape">{t('form.profilePictureShape.label')}</label>
              <Controller
                name="profile_picture_shape"
                id="profile-picture-shape"
                rules={{ required: false }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.profilePictureShape.placeholder')}
                    cacheOptions
                    defaultOptions
                    value={profilePictureShape}
                    options={optionsProfilePictureShape}
                    onChange={handleChangeProfilePictureShape}
                    styles={selectCustomStyles}
                    theme={selectTheme}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  />
                )}
              />
            </div>
            <div className="form-group mb-3">
              <div>
                <label className="form-label" htmlFor="company-picture">{t('form.companyLogo.label')}</label>
                <span className="float-end"><i className="bi-info-circle" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('form.companyLogo.tooltip')}></i></span>
              </div>
              <Controller
                name="company_logo"
                id="company-logo"
                rules={{ 
                  required: false,
                  pattern: {
                    value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                    message: t('form.companyLogo.valid'),
                  },              
                }}
                disabled={isSubmitting}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control form-control-sm"
                    placeholder={t('form.companyLogo.placeholder')}
                    maxLength="2000"
                    value={companyLogo}
                    onChange={handleChangeCompanyLogo}
                  />
                )}
              />
              {errors.company_logo && errors.company_logo.type === "pattern" && <div className="text-danger">{t('form.companyLogo.valid')}</div>}
            </div>
          </div>
        </div>
      </div>
    </form>
  );

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-12 col-lg-5 order-last order-lg-first">
            {showForm}
          </div>
          <div className="col-12 col-lg-7 order-first order-lg-last">
            <div className="sticky-md-top">
              <figure className="device-browser w-100">
                <div className="device-browser-header bg-light mw-100">
                  <div className="device-browser-header-btn-list">
                    <span className="device-browser-header-btn-list-btn bg-danger"></span>
                    <span className="device-browser-header-btn-list-btn bg-warning"></span>
                    <span className="device-browser-header-btn-list-btn bg-success"></span>
                  </div>
                  <div className="device-browser-header-browser-bar">https://lasmallagency.com</div>
                </div>
                <div className="device-browser-frame">
                  <div className="d-flex justify-content-center p-9">
                    <div dangerouslySetInnerHTML={{__html: signatureHTML }}></div>
                  </div>
                </div>
              </figure>
              <div className="d-flex justify-content-center align-items-center pt-4">
                <div className="d-grip gap-2">
                  <button className="btn btn-primary btn-transition mt-2 me-2" onClick={() => handleClickCopySignature()}><i className="bi bi-clipboard-check me-1"></i>{t('copySignature.button')}</button>
                  <button className="btn btn-primary btn-transition mt-2 me-2" onClick={() => handleClickCopySignatureSourceCode()}><i className="bi bi-file-code me-1"></i>{t('copySignatureSourceCode.button')}</button>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center pt-2">
                <div className="d-grip gap-2">
                  <button className="btn btn-soft-primary btn-sm btn-transition mt-2 me-2" onClick={() => handleClickResetInputFields()}><i className="bi bi-trash me-1"></i>{t('resetInputFields.button')}</button>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center pt-4">
                <div className="d-flex gap-2">
                  <ShareBlock 
                    title={t('share.title')}
                    description={t('share.description')}
                    blockStyle="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSignatureGeneratorForm